import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { enUS as muiPickerLocaleEN, sk as muiPickerLocaleSK } from 'date-fns/esm/locale';
import { lazy, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useLocation } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { PublicLayout, Suspense } from 'src/layouts';
import theme from 'src/theme/index';
import lang_en from 'src/translations/en.json';
import lang_sk from 'src/translations/sk.json';
import { AuthProvider, useApp } from './actions';
import useGoogleAnalytics from './actions/useGoogleAnalytics';
import { pathname } from './routes/pathname';

const HomeView = lazy(() => import('src/views/Home'));
const ContactView = lazy(() => import('src/views/Contact'));
const PageView = lazy(() => import('src/views/Page'));
const CategoryView = lazy(() => import('src/views/Category'));
const ArticleView = lazy(() => import('src/views/Article'));

function App() {
  const { app, initApp } = useApp();
  const { initGa, pageView } = useGoogleAnalytics();
  const location = useLocation();
  const state = location.state as { view: string | undefined; id: any };
  initGa();

  useEffect(() => {
    initApp();
    pageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <IntlProvider
        key={app.langs.selected}
        defaultLocale={app.langs.selected}
        locale={app.langs.selected}
        messages={(app.langs.selected === 'en' && lang_en) || lang_sk}
      >
        <LocalizationProvider
          locale={(app.langs.selected === 'en' && muiPickerLocaleEN) || muiPickerLocaleSK}
          dateAdapter={AdapterDateFns}
        >
          <AuthProvider>
            <Routes>
              <Route element={<PublicLayout />}>
                <Route
                  path="/"
                  element={
                    <Suspense>
                      <HomeView />
                    </Suspense>
                  }
                />

                <Route
                  path={`/products/*`}
                  element={
                    <Suspense>
                      <CategoryView id={state?.id} />
                    </Suspense>
                  }
                />

                <Route
                  path={`/rent/*`}
                  element={
                    <Suspense>
                      <CategoryView id={state?.id} />
                    </Suspense>
                  }
                />

                <Route
                  path={`/products/:category/:article`}
                  element={
                    <Suspense>
                      <ArticleView />
                    </Suspense>
                  }
                />

                <Route
                  path={`/rent/:article`}
                  element={
                    <Suspense>
                      <ArticleView />
                    </Suspense>
                  }
                />

                <Route
                  path={'/page/*'}
                  element={
                    <Suspense>
                      <PageView />
                    </Suspense>
                  }
                />

                <Route
                  path={pathname.KONTAKT}
                  element={
                    <Suspense>
                      <ContactView />
                    </Suspense>
                  }
                />

                <Route path="*" element={<p>Path not resolved</p>} />
              </Route>
            </Routes>
          </AuthProvider>
        </LocalizationProvider>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;

import ReactGA from 'react-ga';
// HOWTO: https://blog.saeloun.com/2022/02/17/how-to-integrate-react-app-with-google-analytics.html

const useGoogleAnalytics = () => {
    const TRACKING_ID = process.env.REACT_APP_GA || '';
    const initGa = () => {
        ReactGA.initialize(TRACKING_ID);
    };

    const eventTracker = (category: string, action: string = 'action', label: string = 'label') => {
        const eventTracker = ReactGA.event({ category, action, label });
        return eventTracker;
    };

    const pageView = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };
    
    return {
        TRACKING_ID,
        initGa,
        eventTracker,
        pageView
    };
};

export default useGoogleAnalytics;

import {Box, Container, Typography} from '@mui/material';
import {FC} from 'react';
import {Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

type SlideType = { title?: string; description?: string; href: string };

type Props = {
    list: SlideType[];
    autoplay?: boolean;
    duration?: number;
    arrows?: boolean;
    indicators?: boolean;
};

const Slideshow: FC<Props> = ({
                                  list,
                                  autoplay = false,
                                  duration = 2500,
                                  indicators = false,
                                  arrows = false,
                              }) => {
    const zoomInProperties = {
        indicators,
        scale: 1.4,
        autoplay,
        duration,
        arrows,
    };

    return (
        <Box className="slide-container">
            <Slide easing="ease" {...zoomInProperties}>
                {list.map((item: SlideType, index: number) => (
                    <Box key={index} className="each-slide">
                        <Box
                            sx={{
                                backgroundImage: `url(${item.href})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                            }}
                            height={'650px'}
                            display={{xs: 'flex', sm: 'block'}}
                            flexWrap={{xs: 'wrap'}}
                            alignContent={{xs: 'center'}}
                            justifyContent={{xs: 'center', sm: 'flex-start'}}
                        >
                            {item.title && <Container maxWidth={'xl'} disableGutters>
                                <Typography
                                    variant={'h1'}
                                    fontSize={{xs: '5rem', md: '6rem'}}
                                    color={'#fff'}
                                    fontWeight={900}
                                    m={0}
                                >
                                    {item.title}
                                </Typography>
                                <Typography variant={'h2'} color={'#fff'} fontWeight={900}>
                                    {item.description}
                                </Typography>
                            </Container>}
                        </Box>
                    </Box>
                ))}
            </Slide>
        </Box>
    );
};

export default Slideshow
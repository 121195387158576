export const pathname = {
  HOME: '/',
  OKNA: '/products/okna',
  DVERE: '/products/dvere',
  FASADY: '/products/fasady',
  PVC_OKNA: '/products/okna?category=177cf8ec-8bf7-4b4f-9947-d5d9c228cdf0',
  ALU_OKNA: '/products/okna?category=8bd954aa-0f52-4dd5-b95a-9d089e80438d',
  PVC_DVERE: '/products/dvere?category=ad650ce4-e731-4eef-a83d-558443c38b4a',
  ALU_DVERE: '/products/dvere?category=49c45e77-e615-403c-9069-471c7a4b7d06',
  REALIZACIE: '/page/realizacie',
  PROJEKT: '/page/zefektivnenie-stavebnej-vyroby',
  PROJEKT2: '/page/manipulator-na-sklo-doskove-materialy',
  KONTAKT: '/kontakt',
  RENT: '/rent',
};

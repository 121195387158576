import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export const contactSlice = createSlice({
  name: "contact",
  initialState: {
    address: {
      name: "F&F SIMCO, s.r.o.",
      address: "Priemyselná 1",
      zip: "085 01",
      city: "Bardejov"
    },
    additionalInfo: {
      ico: "36515345",
      dic: "2022167202",
      icdph: "SK2022167202",
      bank_account: "",
      tel: "+421 (0)911 567 047",
      email: "info@simco.sk",
    },
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2601.442144065864!2d21.289529315467355!3d49.305909877045984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473e81e8627493dd%3A0xf802bb16cce3c08a!2sSIMCO%20OKNA!5e0!3m2!1ssk!2ssk!4v1649139861440!5m2!1ssk!2ssk",
  },
  reducers: {},
});

export const useContact = () => {
  const contact = useSelector((state: any) => state.contact);

  return {
    contact,
  };
};

export default contactSlice.reducer;

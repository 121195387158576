import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { pathname } from 'src/routes/pathname';
import { TypeNavigation } from 'src/types/navigation';

const list: TypeNavigation[] = [
  {
    id: '0e67f241-b0d1-44de-a784-ee56f275a7fc',
    title: 'Home',
    ico: null,
    pathname: pathname.HOME,
    children: [],
    footerNav: true,
    display: true,
  },
  {
    id: '0e67f241-b0d1-44de-a784-ee56f275a7fc',
    title: 'Okná',
    ico: null,
    pathname: pathname.OKNA,
    categoryId: '6398815d-cf75-4653-834b-a15eed7237ab',
    footerNav: false,
    display: true,
    children: [],
  },
  {
    id: 'd337fbbc-f6a3-4915-ba3f-8b3b8344ba93',
    title: 'Dvere',
    ico: null,
    pathname: pathname.DVERE,
    categoryId: 'e4c729e6-4ae9-4fd5-a553-8c0ef68d0dfb',
    footerNav: false,
    display: true,
    children: [],
  },
  {
    id: 'f3e81648-1b62-4ffb-89c0-f58ebdc29334',
    title: 'Fasady',
    ico: null,
    pathname: pathname.FASADY,
    categoryId: 'c0370a50-abfd-4fca-b5af-22b127d4d4c5',
    footerNav: false,
    display: true,
    children: [],
  },
  {
    id: 'c058f0fa-55c8-4200-a97b-acb2b8c584aa',
    title: 'Prenájom',
    ico: null,
    pathname: pathname.RENT,
    categoryId: '49c45e77-e615-403c-9069-471c7a4b7d07',
    footerNav: true,
    display: true,
    children: [],
  },
  {
    id: 'c058f0fa-55c8-4200-a97b-acb2b8c584af',
    title: 'Realizácie',
    ico: null,
    pathname: pathname.REALIZACIE,
    categoryId: '40cff8f5-dfdc-4e38-95a5-fd5d648debd9',
    footerNav: false,
    display: true,
    children: [],
  },
  {
    id: 'ab1f8bed-c663-47ad-ab4d-89462ff4d26b',
    title: 'Projekt',
    ico: null,
    pathname: '',
    display: true,
    children: [
      {
        id: 'ab1f8bed-c663-47ad-ab4d-89462ff4d26b',
        title: 'Zefektívnenie stavebnej výroby',
        pathname: pathname.PROJEKT,
      },
      {
        id: 'ab1f8bed-c663-47ad-ab4d-89462ff4d26c',
        title: 'Manipulátor na sklo a doskové materiály',
        pathname: pathname.PROJEKT2,
      },
    ],
    footerNav: false,
  },
  {
    id: '94435691-46c9-4f4a-820d-481578f4dd1f',
    title: 'Kontakt',
    ico: null,
    pathname: pathname.KONTAKT,
    display: true,
    children: [],
    footerNav: true,
  },
];

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    list,
  },
  reducers: {},
});

export const useNavigation = () => {
  const navigation = useSelector((state: any) => state.navigation);

  return {
    navigation,
  };
};

export default navigationSlice.reducer;

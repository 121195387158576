import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Button,
  ClickAwayListener,
  Hidden,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  styled,
  SwipeableDrawer,
  useTheme,
} from '@mui/material';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useNavigation } from 'src/actions';
import MuiPalette from 'src/theme/palette';
import { TypeNavigation, TypeNavigationChildren } from 'src/types/navigation';
import Icon from './Icon';
import { Logo } from './Logo';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const Navigation = (props: any) => {
  const theme = useTheme();
  const { target } = props;
  const { navigation } = useNavigation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Root = styled('div')(({ theme }) => ({
    [`& a`]: {
      color: MuiPalette.primary.main,
      margin: '1em',
      fontWeight: 400,
      textDecoration: 'none',
      fontFamily: 'Montserrat',
    },
    [`& a.active`]: {
      textDecoration: 'underline',
    },
  }));

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpenSidebar(open);
    };

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={{ m: 1, ml: 2 }}>
        <Logo />
      </Box>

      <List>
        {navigation.list.map((item: any, index: any) => (
          <ListItemButton
            key={index}
            onClick={() =>
              navigate(item.pathname, {
                state: {
                  view: item.view && item.view,
                  id: item.categoryId && item.categoryId,
                },
              })
            }
          >
            <ListItemText primary={item.title} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );

  if (target === 'footer') {
    return (
      <Root>
        {navigation.list.map((item: any, index: number) => {
          if (item.footerNav) {
            return (
              <NavLink
                to={item.pathname}
                state={{
                  id: item.categoryId && item.categoryId,
                }}
                style={{
                  color: '#fff',
                  marginRight: index === navigation.list.length ? '0' : 'inherit',
                }}
              >
                {item.title}
              </NavLink>
            );
          }
          return null;
        })}
      </Root>
    );
  }

  return (
    <Box>
      <Hidden only={['xs', 'sm']}>
        {navigation.list.map(
          (item: TypeNavigation, index: number) =>
            (item.display && (
              <Box key={index} display={'inline-block'} mx={2} position={'relative'}>
                <NavLink
                  to={item.pathname}
                  id="composition-button"
                  state={{
                    id: item.categoryId && item.categoryId,
                  }}
                  onClick={(event) => item.children.length && handleClick(event)}
                  style={{
                    lineHeight: '1em',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    textDecoration: 'none',
                    color: theme.palette.primary.main,
                    textTransform: 'uppercase',
                    fontSize: '0.9rem',
                  }}
                >
                  <Box
                    component={'div'}
                    sx={{ cursor: 'pointer', display: 'inline-block' }}
                    position={'relative'}
                    ref={anchorRef}
                  >
                    {item.title}
                  </Box>

                  {(item.children.length && (
                    <Box display={'inline-block'} lineHeight={'2em'} paddingTop={'0.6em'}>
                      <Icon size={15}>DownArrow</Icon>
                    </Box>
                  )) ||
                    null}
                </NavLink>

                {item.children.length ? (
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    role={undefined}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}

                    //  disablePortal
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleClick}
                        >
                          {item.children.map((child: TypeNavigationChildren, indexx: number) => (
                            <MenuItem
                              component={'a'}
                              key={indexx}
                              href={child.pathname}
                              onClick={handleClose}
                            >
                              {child.title}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Popover>
                ) : null}
              </Box>
            )) ||
            null,
        )}
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <Button onClick={toggleDrawer('left', true)}>
          <MenuIcon />
        </Button>
        <SwipeableDrawer
          anchor={'left'}
          open={openSidebar}
          onClose={toggleDrawer('left', false)}
          onOpen={toggleDrawer('left', true)}
        >
          {list('left')}
        </SwipeableDrawer>
      </Hidden>
    </Box>
  );
};

//  https://feathericons.com/

import {
    AlertTriangle,
    ArrowDown,
    ArrowUp,
    AtSign,
    Book,
    Bookmark,
    Check,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    Clock,
    Columns,
    Command,
    Copy,
    Download,
    Edit2,
    FilePlus,
    Folder,
    Hash,
    Heart,
    Home,
    Image,
    Info,
    Key,
    Layers,
    List,
    Loader,
    Lock,
    LogIn,
    LogOut,
    Mail,
    Menu,
    MessageSquare,
    PauseCircle,
    Play,
    RotateCw,
    Save,
    Search,
    Server,
    Settings,
    Share2,
    Star,
    StopCircle,
    Tool,
    Trash2,
    Unlock,
    UploadCloud,
    User,
    UserPlus,
    Users,
    UserX,
    X,
    XCircle,
    Facebook,
} from 'react-feather';

interface IconProps {
    children: string;
    size?: number;
    color?: string;
}

const Icon = (props: IconProps) => {
    const { children, size = 20, color = '#000' } = props;

    const getIcon = () => {
        switch (children) {
            case 'Facebook':
                return <Facebook color={color} size={size} />;
            case 'Hash':
                return <Hash color={color} size={size} />;
            case 'AtSign':
                return <AtSign color={color} size={size} />;
            case 'Book':
                return <Book color={color} size={size} />;
            case 'Heart':
                return <Heart color={color} size={size} />;
            case 'MessageSquare':
                return <MessageSquare color={color} size={size} />;
            case 'Bookmark':
                return <Bookmark color={color} size={size} />;
            case 'Home':
                return <Home color={color} size={size} />;
            case 'Folder':
                return <Folder color={color} size={size} />;
            case 'Columns':
                return <Columns color={color} size={size} />;
            case 'Clock':
                return <Clock color={color} size={size} />;
            case 'Tool':
                return <Tool color={color} size={size} />;
            case 'Layers':
                return <Layers color={color} size={size} />;
            case 'Play':
                return <Play color={color} size={size} />;
            case 'Menu':
                return <Menu color={color} size={size} />;
            case 'RotateCw':
                return <RotateCw color={color} size={size} />;
            case 'FilePlus':
                return <FilePlus color={color} size={size} />;
            case 'LogIn':
                return <LogIn color={color} size={size} />;
            case 'LogOut':
                return <LogOut color={color} size={size} />;
            case 'Info':
                return <Info color={color} size={size} />;
            case 'User':
                return <User color={color} size={size} />;
            case 'UserPlus':
                return <UserPlus color={color} size={size} />;
            case 'UserX':
                return <UserX color={color} size={size} />;
            case 'Search':
                return <Search color={color} size={size} />;
            case 'LeftArrow':
                return <ChevronLeft color={color} size={size} />;
            case 'RightArrow':
                return <ChevronRight color={color} size={size} />;
            case 'DownArrow':
                return <ChevronDown color={color} size={size} />;
            case 'ArrowUp':
                return <ArrowUp color={color} size={size} />;
            case 'ArrowDown':
                return <ArrowDown color={color} size={size} />;
            case 'AlertTriangle':
                return <AlertTriangle color={color} size={size} />;
            case 'Loader':
                return <Loader color={color} size={size} />;
            case 'Download':
                return <Download color={color} size={size} />;
            case 'Edit2':
                return <Edit2 color={color} size={size} />;
            case 'Upload':
                return <UploadCloud color={color} size={size} />;
            case 'Edit':
                return <Edit2 color={color} size={size} />;
            case 'Key':
                return <Key color={color} size={size} />;
            case 'Users':
                return <Users color={color} size={size} />;
            case 'Command':
                return <Command color={color} size={size} />;
            case 'Trash':
                return <Trash2 color={color} size={size} />;
            case 'List':
                return <List color={color} size={size} />;
            case 'Check':
                return <Check color={color} size={size} />;
            case 'Star':
                return <Star color={color} size={size} />;
            case 'Star-Filled':
                return <Star color={color} size={size} fill={color} />;
            case 'Lock':
                return <Lock color={color} size={size} />;
            case 'Unlock':
                return <Unlock color={color} size={size} />;
            case 'Mail':
                return <Mail color={color} size={size} />;
            case 'Save':
                return <Save color={color} size={size} />;
            case 'Settings':
                return <Settings color={color} size={size} />;
            case 'Pause':
                return <PauseCircle color={color} size={size} />;
            case 'Stop':
                return <StopCircle color={color} size={size} />;
            case 'Close':
                return <XCircle color={color} size={size} />;
            case 'Image':
                return <Image color={color} size={size} />;
            case 'Server':
                return <Server color={color} size={size} />;
            case 'Share2':
                return <Share2 color={color} size={size} />;
            case 'Copy':
                return <Copy color={color} size={size} />;
            case 'X':
                return <X color={color} size={size} />;
            default:
                return 'noIconFound';
        }
    };

    return <>{getIcon()}</>;
};

export default Icon;

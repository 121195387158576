import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { merge } from 'lodash';
import MuiTypography from './typography';
//import MuiTextField from "src/theme/config_textfield";
import MuiPalette from './palette';
//import MuiCheckbox from "src/theme/config_checkbox";
//import MuiAlert from "src/theme/components/MuiAlert";

interface ThemeOptionsProps {
  components?: any;
  typography?: Record<string, any>;
  palette?: Record<string, any>;
}

export const THEMES = {
  name: 'APP',
};

const ThemeOptions: ThemeOptionsProps = {
  typography: MuiTypography,
  palette: MuiPalette,
  components: {
    // MuiAlert,
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: MuiPalette.primary.main,
          boxShadow: 'none',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-row:nth-of-type(even)': {
            background: MuiPalette.grey.even,
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#fff',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#42526e',
          textDecorationColor: '#42526e',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
    //MuiTextField,
    //MuiCheckbox,
    MuiPaper: {
      styleOverrides: {
        root: {
          color: MuiPalette.primary.main,
          overflow: 'hidden',
          overflowY: 'auto',
          '&::-webkit-scrollbar-track': {
            borderRadius: '0',
            backgroundColor: '#f5f5f5 !important',
          },

          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
            backgroundColor: '#f5f5f5 !important',
          },

          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
          },

          '& > .MuiTypography-body1': {
            marginBottom: '1rem',

            '&:last-child': {
              marginBottom: '0',
            },
          },
        },
        outlined: {
          '& > .MuiTypography-body1': {
            marginBottom: '1rem',

            '&:last-child': {
              marginBottom: '0',
            },
          },
          '&.scrollable': {
            overflow: 'hidden',
            overflowY: 'auto',
            '&::-webkit-scrollbar-track': {
              borderRadius: '0',
              backgroundColor: '#f5f5f5 !important',
            },

            '&::-webkit-scrollbar': {
              width: '8px',
              height: '8px',
              backgroundColor: '#f5f5f5 !important',
            },

            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.2)',
            },
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& span': {
            wordBreak: 'break-word',
            margin: '0',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {},
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        row: {
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '4px',
          padding: '0.5em',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: MuiPalette.grey.marked,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: '0',
          color: MuiPalette.text.primary,

          '&.Mui-required': {
            color: MuiPalette.error.main,

            '& svg': {
              color: MuiPalette.error.main,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.Mui-required fieldset': {
            border: '1px solid ' + MuiPalette.error.main,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingLeft: 5,

          '&[required] fieldset': {
            border: '1px solid red',
          },
          '&.Mui-disabled': {
            background: MuiPalette.action.disabledBackground,
          },
          '& textarea': {
            padding: '0 !important',
          },
        },
      },
      input: {
        padding: '0',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-required': {
            color: 'red',
          },
        },
      },
      defaultProps: {
        shrink: {
          transform: 'translate(20px, -10px) scale(0.75)',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '48px',

          '&.Mui-selected': {
            color: MuiPalette.primary.main,
            background: 'rgb(243, 245, 249)',
            fontWeight: 700,
          },
          '&.Mui-error': {
            color: MuiPalette.error.main,
            fontWeight: 700,
          },
          '&.Mui-hidden': {
            display: 'none',
          },
          '& svg': {
            marginRight: '0.5em',
            width: '18px',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          margin: '0',
          padding: '0.5em 16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          fontSize: '1.1em',
          fontWeight: 500,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0',
          margin: '1em',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '1em 1em 1em',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, 0.12)',
        },
      },
    },
  },
};

const theme = createTheme(merge(ThemeOptions), { direction: 'ltr' });

export default responsiveFontSizes(theme);

import { Container, Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';
import { useCms, useSeo } from 'src/actions';
import { Logo, Navigation } from 'src/components';
import { FooterLayout } from 'src/layouts';

export const PublicLayout = () => {
    const location = useLocation();
    const {
        cms: { pageInfo },
    } = useCms();
    const { seo } = useSeo();

    return (
        <>
            <Helmet>
                <title>{pageInfo?.title + ' | ' + seo.name}</title>
                <meta property="og:title" content={pageInfo?.title || seo.name} />
                <meta name="description" content={pageInfo?.description} />
                <link rel="canonical" href={`https://www.simco.sk${pageInfo?.pathname || location.pathname}`} />
            </Helmet>

            <Container disableGutters={false} maxWidth={'xl'}>
                <Stack
                    direction={'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ height: '100px' }}
                >
                    <Logo />
                    <Navigation />
                </Stack>
            </Container>

            <Outlet />

            <FooterLayout />
        </>
    );
};

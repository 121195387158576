import axios from "axios";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useApp } from "src/actions";

interface AuthContextType {
  user: any;
  signin: (user: any, callback: any) => void;
  register: (user: any, callback: any) => void;
  signout: () => void;
  me: () => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { app } = useApp();
  let [user, setUser] = React.useState<any>(null);

  let signin = (data: any, callback: any) => {
    axios
      .post(`${app.api}login`, data)
      .then((response) => {
        setUser(response.data);
        localStorage.setItem("type", response.data.type);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("expires_at", response.data.expires_at);
        me();
        callback();
      })
      .catch((error) => {
        callback(error.response.status);
      });
  };

  let register = (data: any, callback: any) => {
    axios
      .post(`${app.api}register`, data)
      .then((response) => {
        setUser(response.data);
        console.log("RESP", response);
        localStorage.setItem("type", response.data.type);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("expires_at", response.data.expires_at);
        me();
        callback();
      })
      .catch((error) => {
        callback(error.response.status);
      });
  };

  let signout = () => {
    /* setUser(null);
    localStorage.removeItem("access_token"); */
  };

  let me = async () => {
    /* if (config.endpoint !== null) {
      axios
        .get(`${config.endpoint}Accounts/me`, {
          headers: {
            "Accept-Language": "en",
            Authorization: `${
              evnOnly ? "Token " : "Basic "
            }${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          dispatch({ type: "authorization/profile", payload: response.data });
        })
        .catch((error) => errorHandle(error));
    } */
  };

  let value = { user, signin, register, signout, me };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();
  //const profile = useSelector((state: any) => state.authorization.profile);

  /*   if (profile === null) {
    auth.me();
  }
 */
  if (!localStorage.getItem("access_token")) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return children;
}

import { createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pathname } from 'src/routes/pathname';
import { TypeArticle, TypeCategory, TypePage } from 'src/types/cms';
import { TypeSeo } from 'src/types/seo';

type TypeState = {
  categories: TypeCategory[];
  articles: TypeArticle[];
  pages: TypePage[];
  pageInfo: { title: string; description: string; pathname: string };
};

const initialState: TypeState = {
  articles: [
    {
      id: 2,
      categoryId: '177cf8ec-8bf7-4b4f-9947-d5d9c228cdf0',
      title: 'HENSWIN',
      description: 'Dizajn a úspornosť',
      pathname: pathname.OKNA + '/plastove-okna-hensfort-reysol',
      content: `Plastové okná HENSFORT Reysol sú výnimočným a exkluzívnym výrobkom našej firmy – výnimočné a najteplejšie okná v našej ponuke. 
      Na okná systému HENSFORT Reysol sme právom hrdí, je to výsledok našej dlhoročnej a intenzívnej práce na vytvorení výnimočných okien, 
      ktoré splnia očakávania a požiadavky tých najnáročnejších investorov. Výnimočný dizajn zvýrazňuje štýl budov a dáva im individuálny ráz. 
      Okná Reysol majú najvyššie tepelnoizolačné vlastnosti Uw = 0,66 W/(m2·K), čo v spojení s tepelnoizolačnou kompozitnou výstužou v ráme robí zo systému HENSFORT REYSOL jeden z najteplejších a energeticky najúspornejších systémov.`,
      image: 'https://www.hensfort.com/upload/products/20/reysol-468.webp',
      gallery: null,
      list: [
        {
          title: 'PROFIL DECEUNINCK',
          content: [
            'Bezolovnatý profil',
            'Stavebná šírka 84 mm, 6 komôr',
            'Montážna výška 120 mm',
            'Šírka zasklenia 36 mm',
            'Neviditeľné časti profilov vyrobené z materiálu EcoPowerCore (recyklovaný profil - vnútro)',
            'Krídlo nezaoblené',
            'Jedinečná dvojbodová zasklievacia lišta',
            'Tepelná izolácia okna Uw = 0,66 W/(m²·K) (pri referenčnom okne)',
          ],
        },
        {
          title: 'FAREBNOSŤ',
          content: [
            'Biela farba; štandardné farby; neštandardné farby, duofarby',
            'Šedý základný profil v strede - jedinečný na trhu (len pre šedé odtiene na oboch stranách)',
          ],
        },
        {
          title: 'VÝSTUŽE',
          content: [
            'Teplá kompozitná výstuž v ráme',
            'Možnosť výstuže krídla NAZ184; umožňuje vykonávanie veľkých konštrukcií',
            'Oceľová výstuž v krídle',
          ],
        },
        {
          title: 'KOVANIE SIEGENIA TITAN AF',
          content: [
            'Otáčavý valčekový hríbik',
            'Zdvíhač krídla',
            'Komponent proti vlámaniu',
            'Štandardné kryté pánty Siegenia Axxent 24+',
            'Mikroventilácia',
            'Poistka proti nesprávnej polohe kľučky',
            'Možnosť polohovania do triedy RC2',
            'Kľučka Hoppe Secustic',
          ],
        },
        {
          title: 'ZASKLENIE',
          content: ['Štandardné sklo Ug = 0,4 W(m²·K) vyplnené kryptónom', ''],
        },
      ],
    },
    {
      id: 7,
      categoryId: '177cf8ec-8bf7-4b4f-9947-d5d9c228cdf0',
      title: 'HENSPERFECT',
      description: 'Investícia do rodinného tepla',
      pathname: pathname.OKNA + '/plastove-okna-civis',
      content: `Výber plastových okien HENSFORT Civis je investíciou do rodinného tepla a bezpečnosti a zároveň zárukou nižších nákladov na vykurovanie. \n
      Okná systému HENSFORT Civis sa postupne stali jedným z najpopulárnejších systémov plastových okien nielen v ponuke našej firmy, ale na celom trhu okien. Uplatňujú sa v rodinných domoch aj v obytných komplexoch. Systém CIVIS sa líši vysokými parametrami energetickej úspornosti, bezolovnatým profilom a bezpečnostným kovaním.`,
      image: 'https://www.hensfort.com/upload/products/14/civis-817.webp',
      gallery: null,
      list: [
        {
          title: 'PROFIL DECEUNINCK',
          content: [
            'Bezolovnatý profil',
            'Stavebná šírka 84 mm, 6 komôr',
            'Montážna výška 120 mm',
            'Šírka zasklenia 24-54 mm',
            'Neviditeľné časti profilov vyrobené z materiálu EcoPowerCore (recyklovaný profil - vnútro)',
            'Jedinečná dvojbodová zasklievacia lišta',
            'Tepelná izolácia okna Uw = 0,75 W/(m²·K) (pri referenčnom okne)',
            'Varianty: krídlo so zaoblenou a nezaoblenou lištou',
          ],
        },
        {
          title: 'FAREBNOSŤ',
          content: [
            'Biela farba; štandardné farby; neštandardné farby, duofarby',
            'Šedý základný profil v strede - jedinečný na trhu (len pre šedé odtiene na oboch stranách)',
          ],
        },
        {
          title: 'VÝSTUŽE',
          content: [
            'Uzavretá oceľová výstuž v krídle',
            'Výstuže pri bielych profiloch (1,5 mm rám; 2 mm krídlo)',
            'Možnosť výstuže krídla NAZ184, umožňuje vykonávanie veľkých konštrukcií',
            'Oceľová výstuž v krídle',
            'Výstuže pri farebných profiloch (1,5 mm rám; 2 mm krídlo)',
          ],
        },
        {
          title: 'KOVANIE SIEGENIA TITAN AF',
          content: [
            'Otáčavý valčekový hríbik',
            'Zdvíhač krídla',
            'Komponent proti vlámaniu',
            'Kľučka Hoppe Secustic',
            'Mikroventilácia',
            'Poistka proti nesprávnej polohe kľučky',
            'Možnosť polohovania do triedy RC2',
            'Kľučka Hoppe Secustic',
          ],
        },
        {
          title: 'ZASKLENIE',
          content: ['Štandardné sklo Ug = 0,5 W/(m²·K)', 'Teplý rámik SSP Advance'],
        },
      ],
    },
    {
      id: 1,
      categoryId: '177cf8ec-8bf7-4b4f-9947-d5d9c228cdf0',
      title: 'HENSVISTA',
      description: 'Dizajn a modernosť',
      pathname: pathname.OKNA + '/plastove-okna-elegant',
      content: `HENSFORT Elegant je moderný a inovatívny okenný systém z PVC, v ktorom hrá hlavnú úlohu štíhly dizajn a veľmi dobré hodnoty tepelnej izolácie.`,
      image: 'https://www.hensfort.com/upload/products/15/okno-2-200.png',
      gallery: [
        'https://www.hensfort.sk/upload/product_colors/23/color-23-colormainphoto-934.webp',
        'https://www.hensfort.sk/upload/product_colors/24/color-24-colormainphoto-47.webp',
        'https://www.hensfort.sk/upload/product_colors/25/color-25-colormainphoto-837.webp',
        'https://www.hensfort.sk/upload/product_colors/26/color-26-colormainphoto-930.webp',
        'https://www.hensfort.sk/upload/product_colors/27/color-27-colormainphoto-373.webp',
        'https://www.hensfort.sk/upload/product_colors/28/color-28-colormainphoto-737.webp',
        'https://www.hensfort.sk/upload/product_colors/29/color-29-colormainphoto-872.webp',
        'https://www.hensfort.sk/upload/product_colors/30/color-30-colormainphoto-270.webp',
        'https://www.hensfort.sk/upload/product_colors/31/color-31-colormainphoto-163.webp',
        'https://www.hensfort.sk/upload/product_colors/32/color-32-colormainphoto-860.webp',
      ],
      list: [
        {
          title: 'FAREBNOSŤ',
          content: ['Obohatená farebná paleta z Deceuninck (50 fariebných laminácií)'],
        },
        {
          title: 'KOVANIE SIEGENIA TITAN AF',
          content: [
            'Otáčavý hríbik',
            'Zdvíhač krídla',
            '2 komponenty proti vlámaniu ',
            'Mikroventilácia',
            'Blokovanie nesprávnej polohy kľučky',
            'Kľučka Dublin',
          ],
        },
        {
          title: 'ZASKLENIE',
          content: [
            'Šírka zasklenia:  Elegant - do 53 mm (hranatá zasklievacia lišta) alebo 59 mm (zaoblená zasklievacia',
            'Ciepła ramka SSP Advance Teplý rámček SSP Advance',
            'Štandardné zasklenie: Ug = 0,5 W / (m² • K)',
          ],
        },
      ],
    },
    {
      id: 3,
      categoryId: '177cf8ec-8bf7-4b4f-9947-d5d9c228cdf0',
      title: 'HENSVIEW',
      description: 'Dizajn a úspornosť',
      pathname: pathname.OKNA + '/plastove-okna-ecoreina',
      content: `Plastové okná systému HENSFORT Ecoreina sú oknami novej generácie s vynikajúcimi izolačnými vlastnosťami a nízkym súčiniteľom priestupu tepla. \n Systém plastových okien HENSFORT Ecoreina pomôže výrazne znížiť náklady na vykurovanie a vytvoriť v interiéri komfortné podmienky. Systém HENSFORT ECOREINA získal uznanie medzi investormi, ktorí dbajú o ekológiu, ktorí používajú riešenia priaznivé pre životné prostredie. Používaním profilov vyrobených z recyklovaných materiálov vyjadrujeme svoju snahu o minimalizovanie vplyvu na životné prostredie a objekt sa zároveň stáva bezpečnejším a teplejším.`,
      image: 'https://www.hensfort.com/upload/products/16/ecoreina-895.webp',
      gallery: null,
      list: [
        {
          title: 'PROFIL DECEUNINCK',
          content: [
            'Bezolovnatý profil',
            'Varianty: krídlo so zaoblenou a nezaoblenou lištou',
            'Montážna výška 120 mm',
            'Šírka zasklenia 24-54 mm',
            'Stavebná šírka 84 mm, 6 komôr',
            'Certifikát „Trieda S“ (Taliansko) - týka sa zmesi, z ktorej sú vyrobené profily',
            'Jedinečná dvojbodová zasklievacia lišta',
            'Tepelná izolácia okna Uw = 0,75 W/(m²·K) (pri referenčnom okne)',
          ],
        },
        {
          title: 'FAREBNOSŤ',
          content: [
            'Biela farba; štandardné farby; neštandardné farby, duofarby',
            'Šedý základný profil v strede - jedinečný na trhu (len pre šedé odtiene na oboch stranách)',
          ],
        },
        {
          title: 'VÝSTUŽE',
          content: [
            'Uzavretá oceľová výstuž v krídle',
            'Výstuže pri bielych profiloch (1,5 mm rám; 2 mm krídlo)',
            'Možnosť výstuže krídla NAZ184, umožňuje vykonávanie veľkých konštrukcií',
            'Oceľová výstuž v krídle',
            'Výstuže pri farebných profiloch (1,5 mm rám; 2 mm krídlo)',
          ],
        },
        {
          title: 'KOVANIE SIEGENIA TITAN AF',
          content: [
            'Otáčavý valčekový hríbik',
            'Zdvíhač krídla',
            'Komponent proti vlámaniu',
            'Kľučka Hoppe Secustic',
            'Mikroventilácia',
            'Poistka proti nesprávnej polohe kľučky',
            'Možnosť polohovania do triedy RC2',
          ],
        },
        {
          title: 'ZASKLENIE',
          content: ['Štandardné sklo Ug = 0,5 W/(m²·K)', 'Teplý rámik SSP Advance'],
        },
      ],
    },
    {
      id: 5,
      categoryId: '177cf8ec-8bf7-4b4f-9947-d5d9c228cdf0',
      title: 'HENSPERFECT Aluclip',
      description: 'Okná s hliníkovým obkladom',
      pathname: pathname.OKNA + '/plastove-okna-aluclip',
      content: `Okná systému HENSFORT Aluclip vyberajú investori a kreatívni architekti v Poľsku aj zahraničí. Vďaka vysokým tepelnoizolačným vlastnostiam a najkvalitnejším materiálom sa okná s hliníkovým obalom montujú nielen do moderných domov, ale aj do komerčných objektov. Systém plastových okien s hliníkovým obkladom bol vytvorený pre investorov, ktorí hľadajú riešenia s moderným dizajnom, ktoré sú odolné proti nepriaznivým poveternostným a environmentálnym podmienkam. Plastový systém HENSFORT Aluclip je ekologický (bezolovnatý profil) a zároveň ekonomický (alternatíva hliníkových okien). Neobmedzený výber farieb a štíhly vzhľad plastových okien HENSFORT Aluclip vytvára úplne nových charakter budov a pre ich používateľov komfortné podmienky.`,
      image: 'https://www.hensfort.com/upload/products/21/hensperfect-aluclip-571.webp',
      gallery: null,
      list: [
        {
          title: 'PROFIL DECEUNINCK',
          content: [
            'Bezolovnatý profil',
            'Stavebná šírka 89 mm, 6 komôr',
            'Montážna výška 120 mm',
            'EcoPowerCore (recyklovaný profil - vnútro)',
            'Varianty: krídlo so zaoblenou a nezaoblenou lištou',
            'Jedinečná dvojbodová zasklievacia lišta',
            'Tepelná izolácia okna Uw = 0,75 W/(m²·K)',
          ],
        },
        {
          title: 'FAREBNOSŤ',
          content: [
            'Biela farba; štandardné farby; neštandardné farby, duofarby',
            'Šedý základný profil v strede - jedinečný na trhu (len pre šedé odtiene na oboch stranách)',
          ],
        },
        {
          title: 'KOVANIE SIEGENIA TITAN AF',
          content: [
            'Otáčavý valčekový hríbik',
            'Poistka proti nesprávnej polohe kľučky',
            'Možnosť polohovania do triedy RC2',
            'Mikroventilácia',
            'Západka proti vlámaniu (R, RU)',
            'Hliníková kľučka Hoppe Secustic',
          ],
        },
        {
          title: 'ZASKLENIE',
          content: [
            'Štandardné sklo Ug = 1,1 W(m²·K) Ug - 0,5 W/(m²·K)',
            'Teply ramik  SSP Advance',
          ],
        },
      ],
    },
    {
      id: 4,
      categoryId: '177cf8ec-8bf7-4b4f-9947-d5d9c228cdf0',
      title: 'KÖMMERLING 88 MD',
      description: 'Dizajn a úspornosť',
      pathname: pathname.OKNA + '/plastove-okna-kommerling-88-md',
      content: ``,
      image: 'https://www.hensfort.com/upload/products/19/hensproline88ad-839.webp',
      gallery: null,
    },
    {
      id: 5,
      categoryId: '177cf8ec-8bf7-4b4f-9947-d5d9c228cdf0',
      title: 'KÖMMERLING 76 AD',
      description: '',
      pathname: pathname.OKNA + '/plastove-okna-kommerling-76-ad',
      content: ``,
      image: 'https://www.hensfort.com/upload/products/24/hensproline76ad-119.webp',
      gallery: null,
    },
    {
      id: 6,
      categoryId: '177cf8ec-8bf7-4b4f-9947-d5d9c228cdf0',
      title: 'KÖMMERLING 76 MD',
      description: '',
      pathname: pathname.OKNA + '/plastove-okna-kommerling-76-md',
      content: ``,
      image: 'https://www.hensfort.com/upload/products/25/hensproline76md-235.webp',
      gallery: null,
    },
    {
      id: 9,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'PE50',
      description: 'Hliníkový systém na interiérové konštrukcie',
      pathname: pathname.OKNA + '/hlinikove-okna-pe50',
      content: `Moderné kancelárske priestory, zaujímavý dizajn interiéru, v ktorých má sklo a hliník popredné miesto. Systém HENSFORT PE 50 splní očakávania investorov, ktorí chcú vytvoriť interiér s výnimočným charakterom.
            Systém HENSFORT PE 50 umožňuje rýchle a jednoduché interiérové konštrukcie – ľahké priečky a zasklené plochy, dvere alebo balkóny. Vďaka širokému výberu kovaní dodáva systém hliníkových okien a dverí HENSFORT PE 50 miestnostiam individuálny charakter a vytvára ich úžitkové vlastnosti. Hliníkové okná a dvere HENSFORT PE 50 si vyberajú investori do moderných budov s otvorenými priestormi, ktorým dodáva sklo a hliník výnimočný charakter.`,
      image: 'https://www.hensfort.com/upload/products/30/pe50-1000.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'EN AW-6060 podľa PN-EN 573-3 stav T6 podľa PN-EN 515 Al Mg Si 0,5 F22 podľa noriem DIN 1725 T1, DIN 17615T1',
          ],
        },
        {
          title: 'VÝPLNE',
          content: [
            'Jednoduché alebo viacnásobné zasklenie každým druhom skla alebo nepriehľadné panely s hrúbkou: 10-51 mm',
          ],
        },
        {
          title: 'POVRCHOVÁ ÚPRAVA',
          content: [
            'Práškové lakovanie polyesterovými farbami spĺňajúce požiadavky Qualicoat, na výber sú farby z palety RAL',
            'Lakovanie na „farbu“ dreva',
            'Eloxovanie vo farbách: hliníková, olivová, šampanská, zlatá, „staré zlato“, hnedá - farby spĺňajú požiadavky Qualanod',
          ],
        },
        {
          title: 'TESNENIE',
          content: [
            'Zo syntetického kaučuku EPDM v súlade s normou 7863 a vykonávacou normou ISO 3302-01, E2',
          ],
        },
        {
          title: 'KOVANIA',
          content: ['Iba od renomovaných firiem: Savio, Sobinco, Winkhaus atď.'],
        },
        {
          title: 'POVOLENIA A OSVEDČENIA O KVALITE',
          content: [
            'Počiatočné typové skúšky podľa PN-EN 14351-1, skúšky v oblasti ochrany proti vlámaniu: trieda RC2 podľa PN-EN 1627:2011',
          ],
        },
      ],
    },
    {
      id: 10,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'PE68',
      description: 'Hliníkový systém na veľké zasklené',
      pathname: pathname.OKNA + '/hlinikove-okna-pe68',
      content: `Hliníkový systém HENSFORT PE 68 je zárukou najvyššej kvality, ktorú potvrdzujú realizácie v celej Európe. Hliníkové okná spolu s hliníkovými dverami veľmi často vytvárajú zaujímavé fasádne konštrukcie obytných aj komerčných budov.
            Hliníkový systém HENSFORT PE 68 s veľmi širokou paletou kovaní umožňuje vytvárať veľkoplošné konštrukcie. Vďaka systému s trojkomorovou konštrukciou profilov a veľmi dobrým tepelnoizolačným vlastnostiam je budova teplá a energeticky úsporná. Okrem toho sa vďaka vzájomnej kompatibilite profilov dajú dvere a okná (vitrína) jednoducho spájať, čím možno vytvárať rozmanité varianty fasád. Systém HENSFORT PE 68 sa medzi investormi teší zvláštnemu uznaniu v objektoch s veľkými zasklenými plochami.`,
      image: 'https://www.hensfort.com/upload/products/31/pe-68-720.webp',
      gallery: null,
    },
    {
      id: 11,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'PE78EI',
      description: 'Bezpečnosť na prvom mieste',
      pathname: pathname.OKNA + '/hlinikove-okna-pe78ei',
      content: `Okná a dvere sú jednou z najdôležitejších investícií. Práve od nich vo veľkej miere závisí celková bezpečnosť objektov. Systém HENSFORT PE 78EI je riešením určeným náročným investorom.
            Hliníkové protipožiarne systémy HENSFORT PE 78EI predstavujú bezpečnú a účinnú protipožiarnu a dymotesnú ochranu. Špeciálne ohňovzdorné vložky zaručujú odolnosť proti ohňu a sú pre investorov zárukou, že aj najväčšie budovy sú úplne chránené pred nebezpečenstvami požiaru. Vďaka systému HENSFORT PE 78 EI môžu byť investori spokojní a nemusia sa báť o bezpečnosť svojich budov. `,
      image: 'https://www.hensfort.com/upload/products/34/pe78ei-ppoz-910.webp',
      gallery: null,
      list: [
        {
          title: 'KOVANIA',
          content: ['WINKHAUS, FAPIM, CISA, ECO SCHULTE, NEMEF DORMA,  WILKA, KFV'],
        },
        {
          title: 'OSVĚDČENÍ A CERTIFIKÁTY KVALITY',
          content: ['Technické osvědčení ITB AT-15-7540/2013', 'Požární klasifikace: EI 30, EI 60'],
        },
        {
          title: 'DVA DRUHY PROTIPOZIARNYCH HMÓT:',
          content: ['sadrove a kremićitanove'],
        },
        {
          title: 'PROTIPOŽIARNA TRIEDA',
          content: ['Sm a Sa PN -EN 13501-2'],
        },
      ],
    },
    {
      id: 12,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'PE78N',
      description: 'Hliníkový systém s moderným dizajnom',
      pathname: pathname.OKNA + '/hlinikove-okna-pe78n',
      content: `Ľahkosť, trvácnosť a moderný dizajn – vlastnosti hliníkového systému HENSFORT PE 78N, ktoré si cenia predovšetkým investori, nielen v komerčných budovách, ale čoraz častejšie v rodinných a moderných pasívnych domoch.
            Systém konštrukčných profilov s trojkomorovou štruktúrou profilov s drážkou v kovaní, používaný v oknách a balkónových dverách z plastových profilov. Konštrukčná hĺbka konštrukčných profilov rámov je 78 mm a krídel 86 mm. Vďaka použitiu viackomorových tepelnoizolačných 42 mm preložiek a dvojkomponentového centrálneho tesnenia možno dosiahnuť vysokú úroveň tepelnej izolácie. Systém umožňuje montáž rôznych sklených výplní (jednoduchých a kompaktných skiel) alebo nepriehľadných panelov s hrúbkou 17 mm až 61 mm. Rám verzie PE 78N so súčiniteľom priestupu tepla na úrovni od 1,7 W/(m²·K). Teplejšia verzia PE 78N HI so zatvorenou komorou pod sklom a s izolačnou vložkou s Uf na úrovni od 0,9 W/(m²·K).`,
      image: 'https://www.hensfort.com/upload/products/32/pe-78n-961.png',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'EN AW-6060 podľa PN-EN 573-3 stav T6 podľa PN-EN 515 Al Mg Si 0,5 F22 podľa noriem DIN 1725 T1, DIN 17615T1',
            'Trojkomorová konštrukcia profilov systému Ponzio PE 52 zaručuje ich veľkú pevnosť a umožňuje vykonávanie konštrukcií s veľkou hmotnosťou.',
          ],
        },
        {
          title: 'VÝPLNE',
          content: [
            'Jednovrstvové alebo viacvrstvové zasklenie každým druhom skla alebo nepriehľadné panely',
          ],
        },
        {
          title: 'POVRCHOVÁ ÚPRAVA',
          content: [
            'Práškové lakovanie polyesterovými farbami spĺňajúce požiadavky Qualicoat, na výber sú farby z palety RAL',
            'Lakovanie na „farbu“ dreva',
            'Eloxovanie vo farbách: hliníková, olivová, šampanská, zlatá, „staré zlato“, hnedá - farby spĺňajú požiadavky Qualanod',
          ],
        },
        {
          title: 'TESNENIE',
          content: [
            'Zo syntetického kaučuku EPDM v súlade s normou 7863 a vykonávacou normou ISO 3302-01, E2',
          ],
        },
        {
          title: 'KOVANIA',
          content: ['Iba od renomovaných firiem: Fapim, Savio, Iseo, Cisa, Hautau, Geze atď.'],
        },
        {
          title: 'CHARAKTERISTICKÝ',
          content: [
            'Systém je vybavený trojkomorovou konštrukciou profilov s drážkou v kovaní v štandarde „euro“ a drážkou v kovaní používanou v oknách a balkónových dverách z drevených PVC profilov',
          ],
        },
        {
          title: 'ZVUKOVÁ IZOLÁCIA (PRE SKLO 44.1/16/6)',
          content: [
            'Podľa ukazovateľa RA2 OK2 38 dB',
            'Podľa ukazovateľa Rw Rw 39 dB',
            'Podľa ukazovateľa RA1 OK1 34 dB',
          ],
        },
      ],
    },
    {
      id: 13,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'PE60',
      description: 'Netypické architektonické konštrukcie',
      pathname: pathname.OKNA + '/hlinikove-okna-pe60',
      content: `Spoznajte hliníkový systém HENSFORT PE 60 ktorý si vyberajú investori a kreatívni architekti, pre ktorých má bezpečnosť, kvalita a výnimočný dizajn prvoradý význam.
            Hliníkový systém HENSFORT PE 60 je určený na vytváranie vonkajších konštrukcií. Uplatňuje sa vo veľkorozmerných objektoch, ktoré si vyžadujú stabilné rohové spojenia a množstvo variantov vyhotovenia dverových krídel. Vďaka týmto vlastnostiam, ako aj vďaka svojej funkčnosti a jednoduchej montáži spĺňa hliníkový systém HENSFORT PE 60  požiadavky investorov, ktorí realizujú objekty s netypickými architektonickými kompozíciami. HENSFORT PE 60 sa používa v developerských investíciách, hoteloch a objektoch občianskej vybavenosti. `,
      image: 'https://www.hensfort.com/upload/products/33/pe-60-597.png',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'EN AW-6060 podľa PN-EN 573-3 stav T6 podľa PN-EN 515 Al Mg Si 0,5 F22 podľa noriem DIN 1725 T1, DIN 17615T1',
          ],
        },
        {
          title: 'TEPELNÁ IZOLÁCIA',
          content: ['Súčiniteľ prestupu tepla Uf = 2,0-2,8 W/(m2.K)'],
        },
        {
          title: 'VÝPLNE',
          content: [
            'Jednoduché alebo viacnásobné zasklenie každým druhom skla alebo nepriehľadné panely s hrúbkou: 10-51 mm',
          ],
        },
        {
          title: 'POVRCHOVÁ ÚPRAVA',
          content: [
            'Práškové lakovanie polyesterovými farbami spĺňajúce požiadavky Qualicoat, na výber sú farby z palety RAL',
            'Lakovanie na „farbu“ dreva',
            'Eloxovanie vo farbách: hliníková, olivová, šampanská, zlatá, „staré zlato“, hnedá - farby spĺňajú požiadavky Qualanod',
          ],
        },
        {
          title: 'TESNENIE',
          content: [
            'Zo syntetického kaučuku EPDM v súlade s normou 7863 a vykonávacou normou ISO 3302-01, E2',
          ],
        },
        {
          title: 'KOVANIA',
          content: ['Iba od renomovaných firiem: Savio, Sobinco, Winkhaus atď.'],
        },
        {
          title: 'POVOLENIA A OSVEDČENIA O KVALITE',
          content: [
            'Počiatočné typové skúšky podľa PN-EN 14351-1, skúšky v oblasti ochrany proti vlámaniu: trieda RC2 podľa PN-EN 1627:2011',
          ],
        },
        {
          title: 'ŠTANDARDNÉ VYBAVENIE',
          content: [
            'Szklenie jednokomorowe Ug=1,1 W/(m2.K)',
            'Blokada błędnego położenia klamki',
            'Podnośnik skrzydła',
            'Okucie Titan AF firmy SIEGENIA',
            'Jeden zaczep antywyważeniowy',
            'Klamka standardowa aluminiowa',
          ],
        },
      ],
    },
    {
      id: 14,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'PE96',
      description: '',
      pathname: pathname.OKNA + '/hlinikove-okna-pe96',
      content: ``,
      image: 'https://www.hensfort.com/upload/products/35/pe96-387.webp',
      gallery: null,
    },
    {
      id: 15,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'MB Passive',
      description: '',
      pathname: pathname.OKNA + '/hlinikove-okna-mbpassive',
      content: ``,
      image: 'https://www.hensfort.com/upload/products/132/mb-passive-382.webp',
      gallery: null,
    },
    /*  {
      id: 16,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'MB Slider',
      description: '',
      pathname: pathname.OKNA + '/hlinikove-okna-mbslider',
      content: ``,
      image: 'https://www.hensfort.com/upload/products/131/mb-slider-275.webp',
      gallery: null,
    }, */
    {
      id: 17,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'MB45',
      description: '',
      pathname: pathname.OKNA + '/hlinikove-okna-mb45',
      content: ``,
      image: 'https://www.hensfort.com/upload/products/130/mb45-56.webp',
      gallery: null,
    },
    {
      id: 18,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'MB86 SI',
      description: '',
      pathname: pathname.OKNA + '/hlinikove-okna-mb86si',
      content: ``,
      image: 'https://www.hensfort.com/upload/products/133/mb86-si-847.webp',
      gallery: null,
    },
    {
      id: 19,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'MB79 N',
      description: '',
      pathname: pathname.OKNA + '/hlinikove-okna-mb79n',
      content: ``,
      image: 'https://www.hensfort.com/upload/products/134/mb79-n-69.png',
      gallery: null,
    },
    {
      id: 20,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'MB45',
      description: '',
      pathname: pathname.OKNA + '/hlinikove-okna-m45a',
      content: ``,
      image: 'https://www.hensfort.com/upload/products/135/mb86-471.webp',
      gallery: null,
    },
    {
      id: 21,
      categoryId: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'MB77 HS',
      description: '',
      pathname: pathname.OKNA + '/hlinikove-okna-mb77hs',
      content: ``,
      image: 'https://www.hensfort.com/upload/products/136/mb77-hs-140.webp',
      gallery: null,
    },
    {
      id: 22,
      categoryId: 'ad650ce4-e731-4eef-a83d-558443c38b4a',
      title: 'HENS HST',
      description: 'Moderné posuvné dvere',
      pathname: pathname.DVERE + '/pvc-dvere-hst',
      content: `Veľké zasklené plochy a otvorené priestory sú charakteristickými trendmi súčasných moderných stavieb. Dvere systému HENSFORT HST tento trend nasledujú. Umožňujú vytvárať výnimočné spojenia, v ktorých sa exteriér stáva súčasťou interiéru.
            Vďaka systému HENSFORT HST sa to, čo je vonku, stáva súčasťou interiéru. Priehľadná stena už nie je iba snom, ale sa stáva skutočnosťou. So šírkou 6 metrov a s výškou 3 metrov, presúvané ľahkým pohybom ruky, sú krásnym spojením medzi vybraným priestorom domu a záhradou alebo terasou. 
Ponúkame na výber dva prahy. Nízky prah je ako komfortné riešenie krásnym spojením interiéru a terasy, na druhej strane vysoký prah zaručuje lepšie tepelnoizolačné a vodotesné vlastnosti. HST je ideálnou investíciou, vďaka ktorej môžete vytvoriť krásny a priestranný dom.`,
      image: 'https://www.hensfort.com/upload/gallery/29/hst1-web_0.png',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'Bezolovnatý profil',
            'Pohľadová výška 115 mm (Prah / Krídlo), 177 mm (Rám / Krídlo)',
            'Šírka zasklenia 24-52 mm',
            'Stavebná hĺbka 175 mm, 6 komôr (krídlo 76 mm - 5 komôr)',
            'Unikátna dvojpätková zasklievacia lišta',
            'Tepelná izolácia profilu Rama / Krídlo Uf = 1,3 W/(m2·K), Prah / Krídlo Uf = 2,1 W/(m2·K)',
          ],
        },
        {
          title: 'FAREBNOSŤ',
          content: [
            'Biela farba; štandardné farby; neštandardné farby, duo farby',
            'Sivé jadro základne profilu - unikátne na trhu (iba pre sivé obojstranné farby)',
          ],
        },
        {
          title: 'KOVANIE',
          content: ['PORTAL HS od firmy SIEGENIA'],
        },
        {
          title: 'VÝSTUŽE',
          content: [
            'Výstuže z pozinkovanej ocele (v pohyblivom krídle z hliníka)',
            'Oceľová výstuž v ráme 2 mm',
            'Otvorená výstuž v ráme a v krídle',
            'Výstuž v krídle - oceľová 3 mm, hliníková 3,5 mm',
          ],
        },
      ],
    },
    {
      id: 23,
      categoryId: 'ad650ce4-e731-4eef-a83d-558443c38b4a',
      title: 'HENSACCESS door',
      description: '6-komorový systém',
      pathname: pathname.DVERE + '/pvc-dvere-terasove-prestige',
      content: `Balkónové a terasové dvere HENSFORT Prestige vizuálne zväčšujú miestnosť a zároveň prepúšťajú dovnútra veľké množstvo denného svetla.
            6-komorový systém PVC dverí HENSFORT Prestige zaručuje dokonalú tepelnú izoláciu celých dverí, preto sú mimoriadne energeticky úsporné, a použitie výstuže z pozinkovanej ocele je zárukou požadovanej statiky dverí. Ich moderný a estetický vzhľad zmení podobu celého domu a dvere zároveň dokonale spoja miestnosť so záhradou alebo s terasou.`,
      image: 'https://www.hensfort.com/upload/products/37/prestige-5232-358.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'Bezolovnatý profil',
            'Varianty: rovné krídlo',
            'Unikátna dvojpätková zasklievacia lišta',
            'Tepelná izolácia profilu Rám / Krídlo Uf = 1,2 W/(m2·K), Prah / Krídlo Uf = 1,8 W/(m2·K)',
            'Stavebná hĺbka 76 mm, 6 komôr',
            'Pohľadová výška 118 mm',
            'Šírka zasklenia 24-42 mm',
          ],
        },
        {
          title: 'FAREBNOSŤ',
          content: [
            'Biela farba; štandardné farby; neštandardné farby, duo farby',
            'Sivé jadro základne profilu - unikátne na trhu (iba pre sivé obojstranné farby)',
          ],
        },
        {
          title: 'VÝSTUŽE',
          content: [
            'Výstuže z pozinkovanej ocele',
            'Výstuže pri bielych profiloch 1,5 mm rám aj krídlo',
            'Otvorená výstuž v ráme a v krídle',
            'Výstuže pri farebných profiloch (1,5 mm rám; 2,5 mm krídlo)',
          ],
        },
        {
          title: 'KOVANIE',
          content: [
            'Automatická západka MACO Z-TF',
            'dva hríbové čapy',
            'odolnosť voči vlámaniu trieda RC2',
            'tri západky - stály prítlak po celej výške dverí',
            'dve západky proti vlámaniu',
            'pánty JOCKER',
          ],
        },
        {
          title: 'ZASKLENIE',
          content: [
            'Jednokomorové viacvrstvové zasklenie',
            'Hliníkový rámik vo viacvrstvovom zasklení',
          ],
        },
      ],
    },
    {
      id: 24,
      categoryId: 'ad650ce4-e731-4eef-a83d-558443c38b4a',
      title: 'HENSEFFECT door',
      description: 'Bezpečné a estetické',
      pathname: pathname.DVERE + '/pvc-dvere-vchodove-prestige',
      content: `Systém Hensfort Prestige – bezpečné a estetické vonkajšie dvere, ktoré si vyberajú individuálni investori aj developeri. Systém dáva rodinným domom a komerčným objektom výnimočný charakter.
            Hlavnou výhodou 5-komorových dverí HENSFORT PRESTIGE sú ich vynikajúce izolačné vlastnosti. Sú odolné proti poškodeniam a chránia dom proti vlámaniu vďaka dodatočnej bezpečnostnej výstuži, ktorá je namontovaná v lište zasklenia, ako aj vďaka výstužiam z pozinkovanej ocele. Dvere HENSFORT Prestige sú výnimočne stabilné a investorom zaručujú dlhoročné a bezproblémové používanie.`,
      image: 'https://www.hensfort.com/upload/products/38/wejsciowe-prestige-179.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'Bezolovnatý profil',
            'Varianty: rovné krídlo',
            'Unikátna dvojpätková zasklievacia lišta',
            'Tepelná izolácia profilu Rám / Krídlo Uf = 1,5 W/(m2·K), Prah / Krídlo Uf = 1,8 W/(m2·K)',
            'Stavebná hĺbka 76 mm, 6 komôr',
            'Pohľadová výška 134 mm',
            'Šírka zasklenia 24-42 mm',
          ],
        },
        {
          title: 'FAREBNOSŤ',
          content: [
            'Biela farba; štandardné farby; neštandardné farby, duo farby',
            'Sivé jadro základne profilu - unikátne na trhu (iba pre sivé obojstranné farby)',
          ],
        },
        {
          title: 'VÝSTUŽE',
          content: [
            'Výstuže z pozinkovanej ocele',
            'Výstuže 2 mm v ráme aj krídle',
            'Zavretá výstuž v ráme a v krídle',
          ],
        },
        {
          title: 'KOVANIE',
          content: [
            'Automatická západka MACO Z-TF',
            'dva hríbové čapy',
            'odolnosť voči vlámaniu trieda RC2',
            'tri západky - stály prítlak po celej výške dverí',
            'dve západky proti vlámaniu',
            'pánty dr Hahn KT-V',
          ],
        },
        {
          title: 'ZASKLENIE',
          content: [
            'Jednokomorové viacvrstvové zasklenie',
            'Hliníkový rámik vo viacvrstvovom zasklení',
          ],
        },
      ],
    },
    {
      id: 25,
      categoryId: 'ad650ce4-e731-4eef-a83d-558443c38b4a',
      title: 'HENSFORTE door',
      description: 'Dom a záhrada',
      pathname: pathname.DVERE + '/pvc-dvere-terasove-arcade',
      content: `Dvere systému HENSFORT Arcade vynikajúcim spôsobom spájajú interiér domu so záhradou alebo s terasou, pritom pôsobia moderne a esteticky.
            Terasové dvere HENSFORT ARDCADE výrazne zväčšujú priestor miestnosti a prepúšťajú veľké množstvo prirodzeného svetla. Systém HENSFORT Arcade okrem svojich estetických vlastností zaručuje aj bezpečnosť vďaka výstužiam z pozinkovanej ocele, ktoré sa nachádzajú v ráme aj krídle, a vďaka bezpečnostným čapom. Plastové dvere HENSFORT ARCADE sú vďaka dôsledne vybraným kovaniam zároveň veľmi ľahké a ich používanie je pohodlné, na otváranie a zatváranie dverí stačí jemný pohyb.`,
      image: 'https://www.hensfort.com/upload/products/40/tarasowe-arcade-789.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'Bezolovnatý profil',
            'Varianty: rovné krídlo',
            'Unikátna dvojpätková zasklievacia lišta',
            'Tepelná izolácia profilu Rám / Krídlo Uf = 1,4 W/(m2·K), Prah / Krídlo Uf = 1,8 W/(m2·K)',
            'Stavebná hĺbka 71 mm, 5 komôr',
            'Pohľadová výška 114 mm',
            'Šírka zasklenia 24-40 mm',
          ],
        },
        {
          title: 'FAREBNOSŤ',
          content: [
            'Biela farba; štandardné farby; neštandardné farby, duo farby',
            'Sivé jadro základne profilu - unikátne na trhu (iba pre sivé obojstranné farby)',
          ],
        },
        {
          title: 'VÝSTUŽE',
          content: [
            'Výstuže z pozinkovanej ocele',
            'Výstuže pri bielych profiloch 1,5 mm rám aj krídlo',
            'Otvorená výstuž v ráme a v krídle',
            'Výstuže pri farebných profiloch (1,5 mm rám; 2 mm krídlo)',
          ],
        },
        {
          title: 'KOVANIE',
          content: [
            'Automatická západka MACO Z-TF',
            'dva hríbové čapy',
            'odolnosť voči vlámaniu trieda RC2',
            'tri západky - stály prítlak po celej výške dverí',
            'dve západky proti vlámaniu',
            'pánty JOCKER',
          ],
        },
        {
          title: 'ZASKLENIE',
          content: [
            'Jednokomorové viacvrstvové zasklenie',
            'Hliníkový rámik vo viacvrstvovom zasklení',
          ],
        },
      ],
    },
    {
      id: 26,
      categoryId: 'ad650ce4-e731-4eef-a83d-558443c38b4a',
      title: 'HENSECO door',
      description: 'Široký výber farieb',
      pathname: pathname.DVERE + '/pvc-dvere-vchodove-arcade',
      content: `Dvere HENSFORT Arcade sú určené investorom, ktorí hľadajú bezpečné a estetické dvere do priečelí budov.
            Systém Arcade je zárukou bezpečnosti a vďaka svojím dobrým tepelnoizolačným vlastnostiam aj zárukou tepla vnútri. V systéme Arcade je dostupný široký výber farieb a dekoračných vzorov, preto môže vynikajúco ladiť s každým architektonickým štýlom. Integrovaná viacbodová zámka a výstuž z pozinkovanej ocele zvyšujú ochranu proti vlámaniu a vysoká stabilita dverí, potvrdená mnohými testami, zaručuje dlhoročné bezproblémové používanie.`,
      image: 'https://www.hensfort.com/upload/products/41/wejsciowe-arcade-548.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'Bezolovnatý profil',
            'Varianty: rovné krídlo',
            'Unikátna dvojpätková zasklievacia lišta',
            'Tepelná izolácia profilu Rám / Krídlo Uf = 1,6 W/(m2·K), Prah / Krídlo Uf = 1,9 W/(m2·K)',
            'Stavebná hĺbka 71 mm, 4 komory',
            'Pohľadová výška 129 mm',
            'Šírka zasklenia 24-40 mm',
          ],
        },
        {
          title: 'FAREBNOSŤ',
          content: [
            'Biela farba; štandardné farby; neštandardné farby, duo farby',
            'Sivé jadro základne profilu - unikátne na trhu (iba pre sivé obojstranné farby)',
          ],
        },
        {
          title: 'VÝSTUŽE',
          content: [
            'Výstuže z pozinkovanej ocele',
            'Výstuže 2 mm v ráme aj krídle',
            'Zavretá výstuž v ráme a v krídle',
          ],
        },
        {
          title: 'KOVANIE',
          content: [
            'Automatická západka MACO Z-TF',
            'dva hríbové čapy',
            'odolnosť voči vlámaniu trieda RC2',
            'tri západky - stály prítlak po celej výške dverí',
            'dve západky proti vlámaniu',
            'pánty dr Hahn KT-V',
          ],
        },
        {
          title: 'ZASKLENIE',
          content: [
            'Jednokomorové viacvrstvové zasklenie',
            'Hliníkový rámik vo viacvrstvovom zasklení',
          ],
        },
      ],
    },
    {
      id: 27,
      categoryId: 'ad650ce4-e731-4eef-a83d-558443c38b4a',
      title: 'PS Comfort',
      description: 'Odolný a bezpečný PVC systém',
      pathname: pathname.DVERE + '/pvc-dvere-ps-comfort',
      content: `Veľké zasklené plochy a otvorené priestory sú charakteristickými trendmi súčasných moderných stavieb. Dvere systému HENSFORT PS COMFORT tento trend nasledujú. Umožňujú vytvárať výnimočné spojenia, v ktorých sa exteriér stáva súčasťou interiéru.
            Sklopno-posuvné dvere HENSFORT PS Comfort umožňujú vytvárať priestor miestností. Predstavujú spojenie praktickosti, modernosti, ako aj slobodného prepúšťania množstva prirodzeného svetla dovnútra. Populárna zástavba je ideálnou náhradou štandardných balkónov, predovšetkým vtedy, keď sa nemusia otvárať obe krídla dverí. Plastové dvere HENSFORT PS COMFORT sú odpoveďou na požiadavky najkreatívnejších investorov a architektov.`,
      image: 'https://www.hensfort.com/upload/products/42/ps-comfort-29.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'Bezolovnatý profil',
            'Varianty: rovné a predsadené krídlo',
            'Unikátna dvojpätková zasklievacia lišta',
            'Tepelná izolácia profilu v závislosti od systému (Uf = 0,95 W/(m2·K) - 1,2 W/(m2·K))',
            'Stavebná hĺbka: Leader 71 mm, 5 komôr; Prestige 76 mm, 5 komôr; Energy 84 mm, 6 komôr',
            'Pohľadová výška v závislosti od systému (125 - 135 mm)',
            'Šírka zasklenia v závislosti od systému (24 - 54 mm)',
          ],
        },
        {
          title: 'FAREBNOSŤ',
          content: [
            'Biela farba; štandardné farby; neštandardné farby, duo farby',
            'Sivé jadro základne profilu - unikátne na trhu (iba pre sivé obojstranné farby)',
          ],
        },
        {
          title: 'VÝSTUŽE',
          content: [
            'Výstuže z pozinkovanej ocele',
            'Výstuže pri bielych profiloch 1,5 mm rám aj krídlo',
            'Otvorená výstuž v ráme a v krídle',
            'Výstuže pri farebných profiloch (1,5 mm rám; 2 mm krídlo)',
          ],
        },
        {
          title: 'KOVANIE',
          content: [
            'PORTAL PSKPlus od firmy SIEGENIA - dve verzie: PSK 160 Plus, PSK 200 Plus  s funkciou',
            'uľahčenia výklopu',
            'Možnosť dodatočného zvýšenia bezpečnosti na triedu RC2',
            'Západka proti vlámaniu',
          ],
        },
        {
          title: 'ZASKLENIE',
          content: [
            'Jednokomorové viacvrstvové zasklenie',
            'Hliníkový rámik vo viacvrstvovom zasklení',
          ],
        },
      ],
    },
    {
      id: 28,
      categoryId: '49c45e77-e615-403c-9069-471c7a4b7d06',
      title: 'PE 78EI',
      description: 'Bezpečná a účinná protipožiarna ochrana',
      pathname: pathname.DVERE + '/hlinikove-dvere-pe78ei',
      content: `Okná a dvere sú jednou z najdôležitejších investícií. Práve od nich vo veľkej miere závisí celková bezpečnosť objektov. Systém HENSFORT PE 78EI je riešenie určené náročným investorom.
            Okná a dvere sú jednou z najdôležitejších investícií. Práve od nich vo veľkej miere závisí celková bezpečnosť objektov. Systém HENSFORT PE 78EI je riešenie určené náročným investorom. Hliníkové protipožiarne systémy HENSFORT PE 78EI predstavujú bezpečnú a účinnú protipožiarnu a dymotesnú ochranu. Špeciálne ohňovzdorné vložky zaručujú odolnosť proti ohňu a sú pre investorov zárukou, že aj najväčšie budovy sú úplne chránené pred nebezpečenstvami požiaru. Vďaka systému HENSFORT PE 78 EI môžu byť investori spokojní a nemusia sa báť o bezpečnosť svojich budov.`,
      image: 'https://www.hensfort.com/upload/products/49/pe-78ei-950.webp',
      gallery: null,
    },
    {
      id: 29,
      categoryId: '49c45e77-e615-403c-9069-471c7a4b7d06',
      title: 'SL 1600TT',
      description: 'Systém do moderných stavieb',
      pathname: pathname.DVERE + '/hlinikove-dvere-sl1600tt',
      content: `Veľké zasklené plochy a otvorené priestory sú charakteristickými trendmi súčasných moderných stavieb. Dvere systému HENSFORT SL 1600tt tento trend nasledujú. Umožňujú vytvárať výnimočné spojenia, v ktorých sa exteriér stáva súčasťou interiéru.
            Zdvíhaco-posuvné dvere HENSFORT SL 1600 TT umožňujú získať nové, otvorené priestory: zimné záhrady sa ľahkým pohybom ruky menia na skutočnú záhradu a terasa alebo balkón predĺžia obytný priestor. Výnimočná stabilita hliníka umožňuje projektovať veľké zasklené plochy, vďaka ktorým budovy získavajú priestrannosť a interiér optimálny prístup denného svetla.`,
      image: 'https://www.hensfort.com/upload/products/48/sl-1600-tt-801.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'EN AW-6060 podľa PN-EN 573-3 stav T66 podľa PN-EN 515 Al Mg Si 0,5 F22 v súlade s normami DIN 1725 T1, DIN 17615 T1',
          ],
        },
        {
          title: 'TESNENIE',
          content: [
            'Zo syntetického kaučuku EPDM v súlade s normou 7863 a vykonávacou normou ISO 3302-01, E2',
          ],
        },
        {
          title: 'HĹBKA KRÍDLA',
          content: ['67 mm'],
        },
        {
          title: 'HĹBKA RÁMU',
          content: ['Dvojkoľajový: 160 mm, trojkoľajový: 247 mm'],
        },
        {
          title: 'VÝPLNE',
          content: [
            'Jednoduché alebo viacnásobné zasklenie každým druhom skla alebo nepriehľadné panely s hrúbkou: do 51 mm',
          ],
        },
        {
          title: 'POVRCHOVÁ ÚPRAVA',
          content: [
            'Práškové lakovanie polyesterovými farbami spĺňajúce požiadavky Qualicoat, na výber sú farby z palety RAL',
            'Lakovanie na „farbu“ dreva',
            'Eloxovanie vo farbách: hliníková, olivová, šampanská, zlatá, „staré zlato“, hnedá - farby spĺňajú požiadavky Qualanod',
          ],
        },
        {
          title: 'SÚČINITEĽ PRESTUPU TEPLA CEZ OKENNÝ RÁM',
          content: ['Uf - od 1,8 W/(m2·K) (SL 1600tt HI)'],
        },
        {
          title: 'TEPELNOIZOLAČNÁ VLOŽKA',
          content: ['34mm+24mm/24 mm +24mm'],
        },
        {
          title: 'POVOLENIA A OSVEDČENIA O KVALITE',
          content: [
            'počiatočné typové skúšky podľa PN-EN 14351 +A1, skúšky v oblasti ochrany proti vlámaniu: trieda RC2 podľa PN-EN 1627',
          ],
        },
      ],
    },
    {
      id: 30,
      categoryId: '49c45e77-e615-403c-9069-471c7a4b7d06',
      title: 'PE68',
      description: 'Hliníkový systém na veľké zasklené plochy',
      pathname: pathname.DVERE + '/hlinikove-dvere-pe68',
      content: `Hliníkový systém HENSFORT PE 68 je zárukou najvyššej kvality, ktorú potvrdzujú realizácie v celej Európe. Hliníkové okná spolu s hliníkovými dverami veľmi často vytvárajú zaujímavé fasádne konštrukcie obytných aj komerčných budov.
            Hliníkový systém HENSFORT PE 68 s veľmi širokou paletou kovaní umožňuje vytvárať veľkoplošné konštrukcie. Vďaka systému s trojkomorovou konštrukciou profilov a veľmi dobrým tepelnoizolačným vlastnostiam je budova teplá a energeticky úsporná. Okrem toho sa vďaka vzájomnej kompatibilite profilov dajú dvere a okná (vitrína) jednoducho spájať, čím možno vytvárať rozličné varianty fasád. Systém HENSFORT PE 68 sa teší zvláštnemu uznaniu medzi investormi do objektov s veľkými zasklenými plochami.`,
      image: 'https://www.hensfort.com/upload/products/46/pe-68-9590-57.webp',
      gallery: null,
    },
    {
      id: 31,
      categoryId: '49c45e77-e615-403c-9069-471c7a4b7d06',
      title: 'PE 78N',
      description: 'Ľahkosť, trvácnosť a moderný dizajn',
      pathname: pathname.DVERE + '/hlinikove-dvere-pe78n',
      content: `Ľahkosť, trvácnosť a moderný dizajn – vlastnosti hliníkového systému HENSFORT PE 78N, ktoré oceňujú predovšetkým investori, nielen v komerčných budovách, ale čoraz častejšie v rodinných a moderných pasívnych domoch.
            Systém konštrukčných profilov s trojkomorovou štruktúrou profilov s drážkou v kovaní, používaný v oknách a balkónových dverách z plastových profilov. Konštrukčná hĺbka konštrukčných profilov rámov je 78 mm a krídel 86 mm. Použitím viackomorových tepelnoizolačných 42 mm preložiek a dvojkomponentového centrálneho tesnenia možno dosiahnuť vysokú úroveň tepelnej izolácie. Systém umožňuje montáž rôznych sklených výplní (jednoduchých a kompaktných skiel) alebo nepriehľadných panelov s hrúbkou 17 mm až 61 mm. Rám verzie PE 78N so súčiniteľom priestupu tepla na úrovni od 1,7 W/(m²·K). Teplejšia verzia PE 78N HI so zatvorenou komorou pod sklom a s izolačnou vložkou s Uf na úrovni od 0,9 W/(m²·K).`,
      image: 'https://www.hensfort.com/upload/products/45/pe-78n-9865-396.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'EN AW-6060 podľa PN-EN 573-3 stav T66 podľa PN-EN 515 Al Mg Si 0,5 F22 v súlade s normami DIN 1725 T1, DIN 17615T1',
          ],
        },
        {
          title: 'TEPELNÁ IZOLÁCIA',
          content: ['Súčiniteľ prestupu tepla cez okenný rám Uf od 1,1 W/(m2.K)'],
        },
        {
          title: 'VÝPLNE',
          content: [
            'Jednoduché alebo viacnásobné zasklenie každým druhom skla alebo nepriehľadné panely s hrúbkou 23-61 mm',
          ],
        },
        {
          title: 'POVRCHOVÁ ÚPRAVA',
          content: [
            'Práškové lakovanie polyesterovými farbami spĺňajúce požiadavky Qualicoat, na výber sú farby z palety RAL',
            'Lakovanie na „farbu“ dreva',
            'Eloxovanie vo farbách: hliníková, olivová, šampanská, zlatá, „staré zlato“, hnedá - farby spĺňajú požiadavky Qualanod',
          ],
        },
        {
          title: 'TESNENIE',
          content: [
            'Zo syntetického kaučuku EPDM v súlade s normou 7863 a vykonávacou normou ISO 3302-01, E2',
          ],
        },
        {
          title: 'KOVANIA',
          content: ['Iba od renomovaných firiem: Fapim, Savio, Iseo, Cisa, Geze, GU, Dorma atď.'],
        },
        {
          title: 'POVOLENIA A OSVEDČENIA O KVALITE',
          content: [
            'počiatočné typové skúšky podľa PN-EN 14351-1 +A1, skúšky v oblasti ochrany proti vlámaniu: trieda RC2 podľa PN-EN 1627',
          ],
        },
        {
          title: 'CHARAKTERISTICKÝ',
          content: [
            'Špeciálne naprojektované kompatibilné profily umožňujú jednoduché prepojenie dverí s vitrínou. Konštrukčná hĺbka profilov pri okenných rámoch aj pri krídlach je 78 mm.',
          ],
        },
      ],
    },
    {
      id: 32,
      categoryId: '49c45e77-e615-403c-9069-471c7a4b7d06',
      title: 'PE50',
      description: 'Hliník v hlavných úlohách',
      pathname: pathname.DVERE + '/hlinikove-dvere-pe50',
      content: `Moderné kancelárske priestory, zaujímavý dizajn interiéru, v ktorých má sklo a hliník popredné miesto. Systém HENSFORT PE 50 splní očakávania investorov, ktorí chcú vytvoriť interiér s výnimočným charakterom.
            Systém HENSFORT PE 50 umožňuje rýchle a jednoduché interiérové konštrukcie – ľahké priečky a zasklené plochy, dvere alebo balkóny. Vďaka širokému výberu kovaní systém hliníkových okien a dverí dáva HENSFORT PE 50 miestnostiam individuálny charakter a vytvára ich úžitkové vlastnosti. Hliníkové okná a dvere HENSFORT PE 50 si vyberajú investori do moderných budov s otvorenými priestormi, ktoré sa vďaka sklu a hliníku stávajú výnimočnými.`,
      image: 'https://www.hensfort.com/upload/products/44/pe-50-183.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'EN AW-6060 podľa PN-EN 573-3 stav T6 podľa PN-EN 515 Al Mg Si 0,5 F22 podľa noriem DIN 1725 T1, DIN 17615T1',
          ],
        },
        {
          title: 'VÝPLNE',
          content: [
            'jednoduché alebo viacnásobné zasklenie každým druhom skla alebo nepriehľadné panely s hrúbkou: 1 - 40 mm',
          ],
        },
        {
          title: 'POVRCHOVÁ ÚPRAVA',
          content: [
            'Práškové lakovanie polyesterovými farbami spĺňajúce požiadavky Qualicoat, na výber sú farby z palety RAL',
            'Lakovanie na „farbu“ dreva',
            'Eloxovanie vo farbách: hliníková, olivová, šampanská, zlatá, „staré zlato“, hnedá - farby spĺňajú požiadavky Qualanod',
          ],
        },
        {
          title: 'TESNENIE',
          content: [
            'Zo syntetického kaučuku EPDM v súlade s normou 7863 a vykonávacou normou ISO 3302-01, E2',
          ],
        },
        {
          title: 'KOVANIA',
          content: ['Iba od renomovaných firiem: Fapim, Savio, Iseo, Cisa, Hautau, Geze atď.'],
        },
        {
          title: 'ZVUKOVÁ IZOLÁCIA (PRE SKLO 44.1/16/6)',
          content: [
            'Podľa ukazovateľa RA2 OK2 38 dB',
            'Podľa ukazovateľa Rw Rw 39 dB',
            'Podľa ukazovateľa RA1 OK1 34 dB',
          ],
        },
        {
          title: '',
          content: ['Onštrukčná hĺbka profilov pri okenných rámoch je 52 mm a pri krídlach 60 mm'],
        },
        {
          title: '',
          content: [
            'Výhodou osovo montovaných pántov je jednoduchá montáž a regulácia v troch rovinách.',
          ],
        },
      ],
    },
    {
      id: 33,
      categoryId: '49c45e77-e615-403c-9069-471c7a4b7d06',
      title: 'PE 78N Hliníkové dvere v jednej rovine',
      description: '',
      pathname: pathname.DVERE + '/hlinikove-dvere-pe78na',
      content: `Riešenie pre najnáročnejších investorov – určené na stavbu dverí so špeciálnou konštrukciou, ktorá umožňuje získať jednu rovinu dverového krídla.
            Vďaka ľahkej a pevnej konštrukcii dverí, ako aj širokému výberu prahov umožňuje toto riešenie pre svoj individuálny charakter investorom navrhovať domy moderným a netypickým spôsobom. Profil krídla je navrhnutý tak, aby po namontovaní výplne bolo krídlo dverí v jednej rovine s rámom.`,
      image: 'https://www.hensfort.com/upload/products/118/pe-78n-aluminiowe-pelne-28.webp',
      gallery: null,
      list: [
        {
          title: 'CHARAKTERISTIKA',
          content: [
            'Jednotný tvar dveří, neviditelné konstrukční prvky',
            'Možnost použít povrchové, skryté nebo válcové závěsy',
            'Estetický vzhled, moderní design',
          ],
        },
        {
          title: 'ĎALŠIE INFORMÁCIE',
          content: [
            'Počet komôr: 3',
            'Tepelná izolácia Uf od 1,5 W/m2K',
            'Hĺbka krídla 78 mm',
            'Tepelnoizolačné vložky so šírkou 34 mm',
          ],
        },
      ],
    },
    {
      id: 34,
      categoryId: 'c0370a50-abfd-4fca-b5af-22b127d4d4c5',
      title: 'PF152 ESG',
      description: 'Systém na ľahké obvodové múry',
      pathname: pathname.FASADY + '/pf152esg',
      content: `Fasády vytvorené systémom HENSFORT PF 152 ESG nájdete na bankách, firmách, nákupných centrách, športových halách a iných objektoch občianskej vybavenosti. Sú odpoveďou na očakávania náročných architektov, ktorí chcú tvoriť priestor vytváraním objektov s veľkými zasklenými plochami.
            Fasádny systém HENSFORT PF 152 ESG je určený na vytváranie ľahkých obvodových stien a konštrukcií s otvorenými priestormi. Vďaka modernému dizajnu, ktorý vytvára hladkú a jednoliatu stenu, rozdelenú neveľkými líniami, je budova moderná a dobre ladí s prostredím firmy. Fasády HENSFORT PF 152 ESG môžete vidieť na mnohých bankách a obchodných centrách a čoraz častejšie využívajú tento systém aj individuálni investori.`,
      image: 'https://www.hensfort.com/upload/products/59/pf-152-esg-868.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'EN AW-6060 podľa PN-EN 573-3 stav T6 podľa PN-EN 515 Al Mg Si 0,5 F22 v súlade s normami DIN 1725 T1, DIN 17615 T1',
          ],
        },
        {
          title: 'TEPELNÁ IZOLÁCIA',
          content: ['Súčiniteľ prestupu tepla cez okenný rám Uf od 1,6 W/(m2.K)'],
        },
        {
          title: 'VÝPLNE',
          content: [
            'Jednovrstvové sklenené tabule alebo viacvrstvové nepriehľadné panely s hrúbkou: 26,28 mm',
            'možnosť použitia trojvrstvového zasklenia (do hrúbky 60 mm)',
          ],
        },
        {
          title: 'POVRCHOVÁ ÚPRAVA',
          content: [
            'Práškové lakovanie polyesterovými farbami spĺňajúce požiadavky Qualicoat, na výber sú farby z palety RAL',
            'Lakovanie na „farbu“ dreva',
            'Eloxovanie vo farbách: hliníková, olivová, šampanská, zlatá, „staré zlato“, hnedá - farby spĺňajú požiadavky Qualanod',
          ],
        },
        {
          title: 'TESNENIE',
          content: [
            'Zo syntetického kaučuku EPDM v súlade s normou 7863 a vykonávacou normou ISO 3302-01, E2',
          ],
        },
        {
          title: 'KOVANIA',
          content: [
            'Iba od renomovaných firiem: Fapim, Savio, Geze, Security Style, Roto, Dorma, Esco, atď.',
          ],
        },
        {
          title: 'POVOLENIA A OSVEDČENIA O KVALITE',
          content: ['Počiatočné typové skúšky podľa PN-EN 13830'],
        },
      ],
    },
    {
      id: 35,
      categoryId: 'c0370a50-abfd-4fca-b5af-22b127d4d4c5',
      title: 'PF 152 HI',
      description: 'Stĺpovo-žrďové obvodové múry',
      pathname: pathname.FASADY + '/pf152hi',
      content: `Fasády vytvorené systémom HENSFORT PF 152 HI nájdete na bankách, firmách, nákupných centrách, športových halách a iných objektoch občianskej vybavenosti. Sú odpoveďou na očakávania náročných architektov, ktorí chcú tvoriť priestor vytváraním objektov s veľkými zasklenými plochami.
            Fasádny systém HENSFORT PF 152 HI určený na vytváranie ľahkých obvodových múrov so stĺpovo-žrďovou konštrukciou umožňuje slobodne vytvárať vzhľad fasády, čo sa týka farebnosti aj geometrických tvarov. Ideálne riešenie na miestach s náročnými poveternostnými podmienkami, ktoré zaručuje budovám ochranu pred vetrom, vodotesnosť, ako aj dobré tepelné a zvukové izolačné vlastnosti. Investori majú záruku, že sa napriek ľahkej konštrukcii ich budova nemusí obávať vplyvu extrémnych poveternostných podmienok. 
            V dvojkomorovej verzii systému HENSFORT PF 152 HI majú stĺpovo-žrďové obvodové múry vysoké tepelnoizolačné vlastnosti, preto sa najčastejšie používajú v energeticky úsporných budovách. Navrhnutá špeciálna tepelnoizolačná vložka vypĺňa priestor medzi sklami, tvarom aj parametrami zlepšuje parametre celej konštrukcie. Štruktúra plastu umožňuje správne odvádzať vodu z upevnení; jeho tvar umožňuje jednoduchú montáž po montáži zasklenia. Tepelnoizolačné vlastnosti umožňujú dosiahnuť súčiniteľ priestupu tepla Ucw na úrovni od 0,6 W/m2K (v prípade výplní s hrúbkou 48 mm).`,
      image: 'https://www.hensfort.com/upload/products/58/pe-152-hi-250.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY (JEDNOKOMOROVÁ)',
          content: [
            'EN AW-6060 podľa PN-EN 573-3 stav T6 podľa PN-EN 515 Al Mg Si 0,5 F22 v súlade s normami DIN 1725 T1, DIN 17615 T1',
          ],
        },
        {
          title: 'TEPELNÁ IZOLÁCIA (JEDNOKOMOROVÁ)',
          content: [
            'Súčiniteľ prestupu tepla cez okenný rám Uf = 1,13 W/(m2·K) v základnej verzii s termikom 24 mm',
          ],
        },
        {
          title: 'VÝPLNE (JEDNOKOMOROVÁ)',
          content: [
            'Jednoduché alebo viacnásobné zasklenie každým druhom skla alebo nepriehľadné panely s hrúbkou 23 - 42 mm',
          ],
        },
        {
          title: 'POVRCHOVÁ ÚPRAVA (JEDNOKOMOROVÁ)',
          content: [
            'Práškové lakovanie polyesterovými farbami spĺňajúce požiadavky Qualicoat, na výber sú farby z palety RAL',
            'Lakovanie na „farbu“ dreva',
            'Eloxovanie vo farbách: hliníková, olivová, šampanská, zlatá, „staré zlato“, hnedá - farby spĺňajú požiadavky Qualanod',
          ],
        },
        {
          title: 'TESNENIE (JEDNOKOMOROVÁ)',
          content: [
            'Zo syntetického kaučuku EPDM v súlade s normou DIN 7863 a vykonávacou normou ISO 3302-01, E2',
          ],
        },
        {
          title: 'KOVANIA (JEDNOKOMOROVÁ)',
          content: [
            'Iba od renomovaných firiem: Fapim, Geze, Security Style, Roto, Dorma, Esco atď',
          ],
        },
        {
          title: 'POVOLENIA A OSVEDČENIA O KVALITE (JEDNOKOMOROVÁ)',
          content: ['počiatočné typové skúšky podľa PN-EN 13830'],
        },
        {
          title: 'HLINÍKOVÉ PROFILY (DVOJKOMOROVÁ)',
          content: [
            'EN AW-6060 podľa PN-EN 573-3 stav T6 podľa PN-EN 515 Al Mg Si 0,5 F22 v súlade s normami DIN 1725 T1, DIN 17615 T1',
          ],
        },
        {
          title: 'TEPELNÁ IZOLÁCIA (DVOJKOMOROVÁ)',
          content: ['súčiniteľ prestupu tepla cez okenný rám Uf= 0,69 W/(m2.K) s termikom 48 mm'],
        },
        {
          title: 'VÝPLNE (DVOJKOMOROVÁ)',
          content: [
            'jednoduché alebo viacnásobné zasklenie každým druhom skla alebo nepriehľadné panely s hrúbkou 38 - 66 mm',
          ],
        },
        {
          title: 'POVRCHOVÁ ÚPRAVA (DVOJKOMOROVÁ)',
          content: [
            'Práškové lakovanie polyesterovými farbami spĺňajúce požiadavky Qualicoat, na výber sú farby z palety RAL',
            'Lakovanie na „farbu“ dreva',
            'Eloxovanie vo farbách: hliníková, olivová, šampanská, zlatá, „staré zlato“, hnedá - farby spĺňajú požiadavky Qualanod',
          ],
        },
        {
          title: 'TESNENIE (DVOJKOMOROVÁ)',
          content: [
            'zo syntetického kaučuku EPDM v súlade s normou DIN 7863 a vykonávacou normou ISO 3302-01, E2',
          ],
        },
        {
          title: 'KOVANIA (DVOJKOMOROVÁ)',
          content: [
            'Iba od renomovaných firiem: Fapim, Geze, Security Style, Roto, Dorma, Esco, atď.',
          ],
        },
        {
          title: 'POVOLENIA A OSVEDČENIA O KVALITE (DVOJKOMOROVÁ)',
          content: [
            'Počiatočné typové skúšky podľa normy PN-EN 13830, certifikát Passivhaus Institut z Darmstadtu',
          ],
        },
      ],
    },
    {
      id: 35,
      categoryId: 'c0370a50-abfd-4fca-b5af-22b127d4d4c5',
      title: 'PT 152 P.požiarna',
      description: 'Bezpečnosť a vysoká ohňovzdornosť',
      pathname: pathname.FASADY + '/pt152p',
      content: `Fasády protipožiarneho systému HENSFORT PT 152 nájdete na bankách, firmách, nákupných centrách, športových halách a iných objektoch občianskej vybavenosti. Sú odpoveďou na očakávania náročných architektov, ktorí chcú tvoriť priestor vytváraním objektov s veľkými zasklenými plochami.
            Bezpečnosť a výnimočný dizajn fasád budov sú hlavnými požiadavkami súčasnej architektúry. Veľké zasklené plochy si vyžadujú, aby sa zachovala náležitá trieda odolnosti proti ohňu. Protipožiarna fasáda HENSFORT PT 152 zaručuje vďaka systému krycích stĺpovo-žrďových stien s vysokou ohňovzdornosťou bezpečnosť objektov a fasád.`,
      image: 'https://www.hensfort.com/upload/products/57/pf-152-188.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'Vnútorné zosilňujúce s protipožiarnymi vložkami a špeciálnymi spojmi priečka-stĺp zaisťujúce nosnosť konštrukcie v prípade výskytu požiaru.',
          ],
        },
        {
          title: 'VÝPLNE',
          content: [
            'Zasklenie jednovrstvové alebo viacvrstvové, protipožiarne, triedy EI 30 alebo EI 60, nepriehľadné výplne; napučiavacie pásky chránia tepelnoizolačné vložky a utesňujú spoje medzi konštrukciou a sklom alebo inou výplňou, vytvárajúc protipožiarny a izolačný prvok',
          ],
        },
        {
          title: 'POVRCHOVÁ ÚPRAVA',
          content: [
            'Dostupné všetky farby z palety RAL (štandardné farby: biela RAL 9016MAT, hnedá RAL 8019MAT, strieborná RAL 9006, sivá RAL 7016MAT)',
            'Eloxácia vo farbách: zlatá, tmavohnedá, šampanská, hliníková',
            'Imitácie dreva: čerešňa, antický dub, dorato, jabloň, mahagón, orech, palisander, borovica, starý dub, višňa, zlatý dub',
          ],
        },
        {
          title: 'POVOLENIA A OSVEDČENIA O KVALITE',
          content: [
            '1561/10/RO3NP „Klasifikácia v oblasti požiarnej odolnosti zavesenej fasády systému Ponzio PF 152 pri plnej aj čiastočnej konfigurácii firmy Ponzio Polska Sp. z o.o.“',
            'Systém dostupný v triedach EI60 a EI30',
            'Klasifikácia v oblasti požiarnej odolnosti LBO-576-K/14',
          ],
        },
      ],
    },
    {
      id: 36,
      categoryId: 'c0370a50-abfd-4fca-b5af-22b127d4d4c5',
      title: '152 SG',
      description: 'Systém stĺpovo-žrďových konštrukcií',
      pathname: pathname.FASADY + '/152sg',
      content: `Fasády vytvorené systémom HENSFORT 152 SG nájdete na bankách, firmách, nákupných centrách, športových halách a iných objektoch občianskej vybavenosti. Sú odpoveďou na očakávania náročných architektov, ktorí chcú tvoriť priestor vytváraním objektov s veľkými zasklenými plochami.
            Systém stĺpovo-žrďových konštrukcií, ktoré sa používajú na stavbu štrukturálnych stien, patrí do materiálovej skupiny RMG 1.0. Konštrukcia steny je opretá na plných stĺpoch, polovičných a žrdiach so šírkou 52 mm. Z vonkajšej strany vytvára jednoliatu hladkú stenu skla, ktorá je rozdelená zvislými a pozdĺžnymi líniami so šírkou 16 mm. Sklené tabule sa dajú v systéme upevňovať niekoľkými spôsobmi: štrukturálnym lepením alebo mechanickým uchopením. Tieto spôsoby umožňujú individuálne formovať tvar vonkajšej steny. Steny možno vytvárať rôznymi spôsobmi, môžu byť rovné alebo zaoblené.`,
      image: 'https://www.hensfort.com/upload/products/56/152-sg-74.webp',
      gallery: null,
      list: [
        {
          title: 'HLINÍKOVÉ PROFILY',
          content: [
            'EN AW-6060 podľa PN-EN 573-3 stav T6 podľa PN-EN 515 Al Mg Si 0,5 F22 v súlade s normami DIN 1725 T1, DIN 17615 T1',
          ],
        },
        {
          title: 'TEPELNÁ IZOLÁCIA',
          content: ['Súčiniteľ prestupu tepla cez okenný rám Uf = 1,45-1,65 W/(m2.K)'],
        },
        {
          title: 'VÝPLNE',
          content: [
            'Jednovrstvové alebo viacvrstvové zasklenie každým druhom skla alebo nepriehľadné panely',
          ],
        },
        {
          title: 'POVRCHOVÁ ÚPRAVA',
          content: [
            'Práškové lakovanie polyesterovými farbami spĺňajúce požiadavky Qualicoat, na výber sú farby z palety RAL',
            'Lakovanie na „farbu“ dreva',
            'Eloxovanie vo farbách: hliníková, olivová, šampanská, zlatá, „staré zlato“, hnedá - farby spĺňajú požiadavky Qualanod',
          ],
        },
        {
          title: 'TESNENIE',
          content: [
            'Zo syntetického kaučuku EPDM v súlade s normou 7863 a vykonávacou normou ISO 3302-01, E2',
          ],
        },
        {
          title: 'PRÍSLUŠENSTVO',
          content: [
            'Rožné spoje, svorníky, skrutky, podložky z hliníka, nehrdzavejúcej ocele alebo pozinkovanej ocele',
          ],
        },
        {
          title: 'KOVANIA',
          content: ['Iba od renomovaných firiem: Fapim, Savio, Geze, Security Style, atď.'],
        },
        {
          title: 'KOVANIA',
          content: [
            'Európske technické osvedčenie ETA -08/0288 „Súprava výrobkov na zhotovenie zavesených fasád s konštrukčným tmelom pre zasklenie“, skúška protipožiarnej odolnosti zavesenej fasády v tzv. plnej konfigurácii (medziposchodový pás a priehľadné sklo) - v triede EI 60 - z vnútornej strany.',
          ],
        },
      ],
    },
    {
      id: 90,
      categoryId: '49c45e77-e615-403c-9069-471c7a4b7d07',
      title: 'Mini žeriav M 250',
      description: 'Je vhodný na miesta, kam sa nedostane autožeriav.',
      pathname: pathname.RENT + '/zeriav-m250',
      content: `Môže sa s ním manipulovať tak pomaly, že dokáže osadiť veľké a ťažké sklo do rámu okna.
            \n Zložený žeriav prejde cez otvor so šírkou 80 cm. Vďaka nízkej hmotnosti je možné, aby sa pohyboval po streche budov a dvíhal rôzne stavebné materiály.`,
      image: '/images/M250.png',
      gallery: [
        '/images/ingombro-M250-new.jpg',
        '/images/m-250-gallery-01.jpg',
        '/images/m-250-gallery-02.jpg',
        '/images/m-250-gallery-03.jpg',
        '/images/M250_carico_1.jpg',
        '/images/M250_carico_2.jpg',
      ],
      list: [
        {
          title: 'Rozmery',
          content: ['1,76 × 0,78 × 3,24 m'],
        },
        {
          title: 'Maximálna zvíhacia kapacita',
          content: ['2 500 kg'],
        },
        {
          title: 'Maximálny dosah',
          content: ['8,43 m'],
        },
        {
          title: 'Maximálny dosah s hydraulickým ramenom',
          content: ['12,85 m'],
        },
        {
          title: 'Výška zdvihu s prídavným ramenom',
          content: ['15,9 m'],
        },
        {
          title: 'Rýchlosť otáčania',
          content: ['Plynulé / 1,5 ot./min'],
        },
        {
          title: 'Maximálna dĺžka lana',
          content: ['68 m'],
        },
        {
          title: 'Systém stability',
          content: ['24 rôznych pozícií - Max 4155x4300'],
        },
        {
          title: 'Trakcia pásového žeriavu',
          content: [
            'Prevodovka: Motor-reduktor',
            'Objem: 50 l',
            'Tlak: 170 barov',
            'Rýchlosť: 0 - 2 K/h',
            'Maximálny stupeň sklonu: 25°',
            'Maximálny tlak na zem: 0,47 Kg/Cm²',
          ],
        },
        {
          title: 'Naftový motor',
          content: [
            'Značka: Yanmar',
            'Model: 3TNV70',
            'Maximálny výkon: 16,3 kw - 22,2 Hp',
            'Maximálny krútiaci moment: 48 Nm',
            'Objem nádrže: 13 l',
          ],
        },
      ],
    },
    {
      id: 91,
      categoryId: '49c45e77-e615-403c-9069-471c7a4b7d07',
      title: 'Iveco Daily 35C13',
      description: 'Hydraulicka ruka Ferrari',
      pathname: pathname.RENT + '/iveco-daily-35c13',
      content: ` `,
      image: '/images/rent/iveco_001.jpeg',
      gallery: [
        '/images/rent/iveco_002.jpeg',
        '/images/rent/iveco_003.jpeg',
        '/images/rent/iveco_004.jpeg',
        '/images/rent/iveco_005.jpeg',
      ],
      list: [
        {
          title: 'Prípustná celková hmotnosť',
          content: ['3500 kg'],
        },
        {
          title: 'Max. nosnosť',
          content: ['1 000kg'],
        },
        {
          title: 'Nosnosť',
          content: ['1 500 kg'],
        },
        {
          title: 'Max. dĺžka ramena',
          content: ['8,8 m'],
        },
        {
          title: 'Rozmer úložného priestoru',
          content: ['3,70 x 2,10 m'],
        },
        {
          title: 'Rôzne',
          content: ['Rádiové ovládanie', 'Možnosť použitia rôznych úchytov'],
        },
      ],
    },
  ],
  categories: [
    {
      id: '40cff8f5-dfdc-4e38-95a5-fd5d648debd9',
      title: 'Realizácie',
      description:
        'Plastové a hliníkové okná značky HENSFORT si vyberajú nielen individuálni investori, ale aj developeri. Vďaka nim získavajú ich rodinné domy a komerčné budovy výnimočný, nevšedný charakter.',
      coverUrl: '/images/slideshow/2.jpg',
      image: '/images/slideshow/2.jpg',
      parentId: null,
      pathname: pathname.REALIZACIE,
    },
    {
      id: '6398815d-cf75-4653-834b-a15eed7237ab',
      title: 'Okná',
      description: `Okná sú jednou z najdôležitejších investícií. Práve od okien vo veľkej miere závisí úroveň životného komfortu domácich.`,
      content_sk: `Plastové okná HENSFORT sú investíciou do rodinného tepla a bezpečnosti a zároveň zárukou zníženia nákladov na vykurovanie. Naše výrobky sú charakteristické veľmi dobrými tepelnoizolačnými vlastnosťami a najkvalitnejšími materiálmi, z ktorých sa vyrábajú. Dlhodobo spolupracujeme so s+kupinou Deceuninck, podstatne sa podieľame na posilňovaní pozície na trhu a vnímania značky plastových okenných profilov Inoutic v strednej a vo východnej Európe.`,
      content_en: null,
      coverUrl: '/images/slideshow/1.jpg',
      image: 'https://www.hensfort.com/upload/products/20/reysol-468.webp',
      parentId: null,
      pathname: pathname.OKNA,
    },
    {
      id: '177cf8ec-8bf7-4b4f-9947-d5d9c228cdf0',
      title: 'PVC OKNÁ',
      description:
        'Okná sú jednou z najdôležitejších investícií. Práve od okien vo veľkej miere závisí úroveň životného komfortu domácich. Už viac ako 20 rokov si energeticky úsporné a bezpečné plastové okná značky HENSFORT vyberajú tí najnáročnejší investori.',
      content_sk:
        'Plastové okná HENSFORT sú investíciou do rodinného tepla a bezpečnosti a zároveň zárukou zníženia nákladov na vykurovanie. Naše výrobky sú charakteristické veľmi dobrými tepelnoizolačnými vlastnosťami a najkvalitnejšími materiálmi, z ktorých sa vyrábajú. Dlhodobo spolupracujeme so s+kupinou Deceuninck, podstatne sa podieľame na posilňovaní pozície na trhu a vnímania značky plastových okenných profilov Inoutic v strednej a vo východnej Európe. Vďaka nášmu angažovaniu sú plastové okná značky HENSFORT známe nielen doma v Poľsku, ale aj v Nemecku, vo Francúzsku, v Taliansku, Spojenom kráľovstve, Belgicku, Česku, na Slovensku, Ukrajine a v USA a množstvo spokojných zákazníkov neustále rastie: Presvedčte sa sami o kvalite plastových okien značky HENSFORT, ktoré si vyberajú investori a kreatívni architekti doma aj v zahraničí.',
      content_en: null,
      coverUrl: '/images/slideshow/1.jpg',
      //image: null,
      parentId: '6398815d-cf75-4653-834b-a15eed7237ab',
      pathname: pathname.PVC_OKNA,
    },
    {
      id: '8bd954aa-0f52-4dd5-b95a-9d089e80438d',
      title: 'Hliníkové okná',
      description:
        'Hliníkové systémy HENSFORT sú estetické a veľmi trvácne riešenia. Používajú sa nielen v individuálnych, ale aj v komerčných projektoch.',

      coverUrl: '/images/slideshow/1.jpg',
      //image: '',
      parentId: '6398815d-cf75-4653-834b-a15eed7237ab',
      pathname: pathname.ALU_OKNA,
    },
    {
      id: 'e4c729e6-4ae9-4fd5-a553-8c0ef68d0dfb',
      title: 'Dvere',
      description: `Našu ponuku energeticky úsporných okien dopĺňajú plastové a hliníkové dvere, čo je odpoveďou na potreby investorov.
        `,
      content_sk: `Energeticky úsporné dvere značky HENSFORT s charakteristickým dizajnom sú bezpečné a majú dobré tepelnoizolačné vlastnosti. Vďaka širokej palete vzorov a farieb si môžete vybrať vhodné dvere k danej fasáde a podľa charakteristických prvkov danej budovy. `,
      content_en: null,
      coverUrl: '/images/slideshow/4.jpg',
      image: 'https://www.hensfort.com/upload/products/49/pe-78ei-950.png',
      parentId: null,
      pathname: pathname.DVERE,
    },
    {
      id: 'ad650ce4-e731-4eef-a83d-558443c38b4a',
      title: 'PVC dvere',
      description: null,
      content_sk: null,
      content_en: null,
      coverUrl: '/images/slideshow/4.jpg',
      image: '/images/plastove_dvere/prestige.png',
      parentId: 'e4c729e6-4ae9-4fd5-a553-8c0ef68d0dfb',
      pathname: pathname.PVC_DVERE,
    },
    {
      id: '49c45e77-e615-403c-9069-471c7a4b7d06',
      title: 'Hlinikové dvere',
      description: null,
      content_sk: null,
      content_en: null,
      coverUrl: '/images/slideshow/4.jpg',
      image: '/images/hlinikove_dvere/prestige.png',
      parentId: 'e4c729e6-4ae9-4fd5-a553-8c0ef68d0dfb',
      pathname: pathname.ALU_DVERE,
    },
    {
      id: 'c0370a50-abfd-4fca-b5af-22b127d4d4c5',
      title: 'Fasády',
      description:
        'Naše fasády nájdete na bankách, firmách, nákupných centrách, športových halách a iných objektoch občianskej vybavenosti.',
      content_sk: null,
      content_en: null,
      coverUrl: '/images/slideshow/15_0.jpg',
      image: 'https://www.hensfort.com/upload/products/59/pf-152-esg-868.webp',
      parentId: null,
      pathname: pathname.FASADY,
    },
    {
      id: '49c45e77-e615-403c-9069-471c7a4b7d07',
      title: 'Prenájom',
      description: null,
      content_sk: null,
      content_en: null,
      coverUrl: '/images/slideshow/4.jpg',
      image: '/images/hlinikove_dvere/prestige.png',
      parentId: null,
      pathname: pathname.RENT,
    },
  ],
  pages: [
    {
      id: '01e67238-b3da-47e3-bcbe-8a0ff52c971e',
      title: 'Okná Dvere Fasády',
      description: 'Výroba a montáž okien a dverí - PVC okná, hliníkové okná',
      pathname: '/',
      content: '',
      image: '',
    },
    {
      id: 'ed7b1f89-e053-4c60-b02d-328da9a2b931',
      title: 'Realizácie',
      description: '',
      pathname: '/page/realizacie',
      content: '',
      image: '',
      type: 'gallery',
      gallery: [
        {
          title: 'Villa Cubach',
          description: 'Spišské Bystre',
          images: [
            {
              src: '/images/realizations/villa_cubach/01.jpg',
              original: '/images/realizations/villa_cubach/01.jpg',
              width: 320,
              height: 174,
              caption: 'Villa Cubach',
            },
            {
              src: '/images/realizations/villa_cubach/02.jpg',
              original: '/images/realizations/villa_cubach/02.jpg',
              width: 320,
              height: 174,
              caption: 'Villa Cubach',
            },
            {
              src: '/images/realizations/villa_cubach/03.jpg',
              original: '/images/realizations/villa_cubach/03.jpg',
              width: 320,
              height: 174,
              caption: 'Villa Cubach',
            },
            {
              src: '/images/realizations/villa_cubach/04.jpg',
              original: '/images/realizations/villa_cubach/04.jpg',
              width: 320,
              height: 174,
              caption: 'Villa Cubach',
            },
          ],
        },
        {
          title: 'Stavba Hurbanova',
          description: 'Bardejov',
          images: [
            {
              src: '/images/realizations/kanc_hurbanova_bj/01.jpg',
              original: '/images/realizations/kanc_hurbanova_bj/01.jpg',
              width: 320,
              height: 174,
              caption: 'Stavba Hurbanova',
            },
          ],
        },
      ],
    },
    {
      id: 'a5324426-39eb-4f80-a66a-8f4cd8c42ff8',
      title: 'Zefektívnenie stavebnej výroby',
      description: 'Operačný program: Integrovaný regionálny operačný program 2014-2020',
      pathname: '/page/zefektivnenie-stavebnej-vyroby',
      content: `            
      Cieľom projektu je zefektívnenie stavebnej výroby firmy F&F SIMCO, s.r.o., t.j. pri výrobe a montáži okenných a dverových systémov a stavebných konštrukcií pri výstavbe pozemných a inžinierskych stavieb a zvýšenie zamestnanosti v regióne.
      Predmetom projektu je obstaranie mini žeriavu. Mini žeriav bude slúžiť na zefektívnenie stavebnej výroby pre firmu F&F SIMCO, s.r.o,, t.j. pri výrobe a montáži okenných a dverových systémov a stavebných konštrukcií pri výstavbe pozemných a inžinierskych stavieb. Ďalej bude slúžiť na zdvíhanie bremien v stiesnených a malých priestoroch, kde sa nedostanú klasické žeriavy: napr, zasklievanie fasád, montáž konštrukcií okien, zdvíhanie bremien. Vďaka zakúpenému mini žeriavu bude možné zákazníkom ponúknuť a hlavne dodať atypické produkty v nadrozmerných veľkostiach a rôznych tvaroch.
      
      Celkové oprávnené výdavky na realizáciu aktivít projektu predstavujú sumu 89 720,00 EUR.
      
      Projekt získal príspevok vo výške 49 346,00 EUR. Tento projekt sa realizuje s podporou EURÓPSKEJ ÚNIE. Spolufinancovaný fondom: Európsky fond regionálneho rozvoja`,
      list: [
        {
          title: 'Prioritná os: č. 5',
          content: ['Miestny rozvoj vedený komunitou'],
        },
        {
          title: 'Investičná priorita: 5.1',
          content: ['Záväzné investície v rámci stratégií miestneho rozvoja vedeného komunitou'],
        },
        {
          title: 'Špecifický cieľ',
          content: [
            '5.1.1 Zvýšenie zamestnanosti na miestnej úrovni podporou podnikania a inovácií ',
          ],
        },
        {
          title: 'Aktivita',
          content: ['A1 Podpora podnikania a inovácií'],
        },
        {
          title: 'Kód výzvy',
          content: ['IROP-CLLD-P964-511-001 v znení aktualizácie č.1'],
        },
        {
          title: 'Fond',
          content: ['Európsky fond regionálneho rozvoja (ďalej len „EFRR”)'],
        },
        {
          title: 'Kód projektu',
          content: ['IROP-CLLD-P964-511-001-002'],
        },
        {
          title: 'Merateľné ukazovatele',
          content: [
            'Počet produktov, ktoré sú pre firmu nové - s cieľovou hodnotou 1',
            'Počet podnikov, ktorým sa poskytuje podpora - s cieľovou hodnotou 1',
            'Počet vytvorených pracovných miest- s cieľovou hodnotou 1',
          ],
        },
      ],
      image: `/images/eu_logos.JPG`,
    },
    {
      id: 'ab1f8bed-c663-47ad-ab4d-89462ff4d26c',
      title: 'Manipulátor na sklo a doskové materiály',
      description: 'Integrovaný regionálny operačný program 2014-2020',
      pathname: '/page/manipulator-na-sklo-doskove-materialy',
      content: `
      Cieľom projektu je zefektívnenie stavebnej výroby a zvýšenie zamestnanosti v území MAS HORNÁ TOPĽA.

      Celkové oprávnené výdavky na realizáciu aktivít projektu predstavujú sumu 45 275,00 EUR. Projekt získal príspevok vo výške 20 464,30 EUR.
      Tento projekt sa realizuje s podporou EURÓPSKEJ ÚNIE. Spolufinancovaný fondom: Európsky fond regionálneho rozvoja

      `,
      list: [
        {
          title: 'Prioritná os: č. 5',
          content: ['č. 5 – Miestny rozvoj vedený komunitou'],
        },
        {
          title: 'Investičná priorita: 5.1',
          content: [
            '5.1 – Záväzné investície v rámci stratégií miestneho rozvoja vedeného komunitou',
          ],
        },
        {
          title: 'Špecifický cieľ',
          content: [
            '5.1.1 Zvýšenie zamestnanosti na miestnej úrovni podporou podnikania a inovácií',
          ],
        },
        {
          title: 'Aktivita',
          content: ['A1 Podpora podnikania a inovácií'],
        },
        {
          title: 'Kód výzvy',
          content: ['IROP-CLLD-P964-511-001 v znení aktualizácie č.1'],
        },
        {
          title: 'Fond',
          content: ['Európsky fond regionálneho rozvoja (ďalej len „EFRR”)'],
        },
        {
          title: 'Kód projektu',
          content: ['IROP-CLLD-P964-511-001-011'],
        },
        {
          title: 'Merateľné ukazovatele',
          content: [
            'Počet produktov, ktoré sú pre firmu nové - s cieľovou hodnotou 1',
            'Počet podnikov, ktorým sa poskytuje podpora - s cieľovou hodnotou 1',
            'Počet vytvorených pracovných miest- s cieľovou hodnotou 0,5',
          ],
        },
      ],
      image: `/images/eu_logos.JPG`,
    },
  ],
  pageInfo: {
    title: '',
    description: '',
    pathname: '',
  },
};

export const cmsSlice = createSlice({
  name: 'cms',
  initialState,
  reducers: {
    pageInfo: (state, action) => {
      state.pageInfo = action.payload;
    },
  },
});

export const useCms = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const cms = useSelector((state: any) => state.cms);
  const { pageInfo } = cmsSlice.actions;

  useMemo(() => {
    let obj: TypeSeo = {
      title: null,
      description: null,
      pathname: null,
    };
    const a = cms.articles.find((obj: TypeArticle) => location.pathname.includes(obj['pathname']));
    const c = cms.categories.find((obj: TypeCategory) =>
      location.pathname.includes(obj['pathname']),
    );
    const p = cms.pages.find((obj: TypePage) => location.pathname.includes(obj['pathname']));

    obj['title'] = a?.title || c?.title || p?.title || '--';
    obj['description'] = a?.description || c?.description || p?.description || '--';
    obj['pathname'] = a?.pathname || c?.pathname || p?.pathname || '--';

    dispatch(pageInfo(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return {
    cms,
  };
};

export default cmsSlice.reducer;

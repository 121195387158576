import { Box, Container, Divider, Hidden, IconButton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useApp } from 'src/actions';
import { Icon, Logo, Navigation } from 'src/components';

type Props = {};

export const FooterLayout: FC<Props> = () => {
    const { app } = useApp();

    return (
        <>
            <Box sx={{ background: '#212121', py: 4 }}>
                <Container disableGutters={false} maxWidth={'xl'}>
                    <Box>
                        <Hidden only={['xs']}>
                            <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={2}>
                                <Logo width={150} variant={'white'} />
                                <Box>
                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                        <Navigation target={'footer'} />
                                        <IconButton
                                            onClick={() => {
                                                window.open('https://www.facebook.com/simcookna', '_blank');
                                            }}
                                        >
                                            <Icon color={'#fff'}>Facebook</Icon>
                                        </IconButton>
                                    </Stack>
                                </Box>
                            </Stack>
                            <Box mt={2} />
                            <Divider sx={{ background: '#fff', height: '2px', opacity: '0.5' }} />
                        </Hidden>

                        <Hidden only={['xs']}>
                            <Stack
                                direction={'row'}
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ mt: 1 }}
                                spacing={2}
                            >
                                <Typography fontSize={'0.9rem'} color={'#fff'}>
                                    <Box>{app.company}</Box>
                                    <Box>{app.copyright}</Box>
                                </Typography>
                                <Typography fontSize={'0.9rem'} color={'#fff'}>
                                    Made by WebLab
                                </Typography>
                            </Stack>
                        </Hidden>

                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                            <Stack alignItems="center" spacing={2}>
                                <Box my={1}>
                                    <Logo width={80} variant={'white'} />
                                </Box>
                                <Typography fontSize={'0.9rem'} color={'#fff'} textAlign={'center'}>
                                    <Box>{app.company}</Box>
                                    <Box>{app.copyright}</Box>
                                </Typography>
                                <Typography fontSize={'0.9rem'} color={'#fff'}>
                                    Made by WebLab
                                </Typography>
                            </Stack>
                        </Hidden>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

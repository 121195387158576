import { Box, Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCms } from 'src/actions';
import { TypeArticle } from 'src/types/cms';

type Props = {};

export const CategoryItems: FC<Props> = (props: any) => {
    const navigate = useNavigate();
    const {
        cms: { articles },
    } = useCms();
    const category = props;

    return (
        <Container maxWidth={'xl'} disableGutters sx={{ mt: 6, mb: 6 }}>
            <Typography variant={'h2'} textAlign={'left'}>
                {category['title']}
            </Typography>
            <Box mt={2} />
            <Grid container spacing={2}>
                {articles.map((item: TypeArticle, index: number) => {
                    if (item.categoryId === category.id) {
                        return (
                            <Grid
                                key={index}
                                item
                                display={{ xs: 'flex', md: 'inline' }}
                                justifyContent={{ xs: 'center', md: 'flex-start' }}
                                width={{ xs: '100%', sm: '33%', md: '25%', lg: '20%', xl: '16.6667%' }}
                            >
                                <Card
                                    elevation={0}
                                    sx={{ cursor: 'pointer', maxWidth: '100%' }}
                                    onClick={() => navigate(item['pathname'])}
                                >
                                    <Box sx={{ background: '#F2F2F2', borderRadius: '4px' }}>
                                        <CardMedia
                                            component="img"
                                            height="270"
                                            sx={{
                                                width: 'inherit',

                                                p: 2,
                                            }}
                                            image={item.image}
                                            alt={item['title']}
                                        />
                                    </Box>

                                    <CardContent sx={{ px: 0 }}>
                                        <Typography variant={'h4'}>{item['title']}</Typography>
                                        <Typography>{item['description']}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    }
                    return null;
                })}
            </Grid>
        </Container>
    );
};
